import { t } from 'i18next'

const url = 'wss://127.0.0.1:13579/'

const initialState = {
	loading: false,
	failed: false,
	success: false,
	data: null
}

export function blockScreen() {
	const overlay = document.createElement('div')
	overlay.setAttribute('id', 'blockUIOverlay')
	document.body.appendChild(overlay)
	overlay.innerHTML = `<img src="lib/loading.gif" style={{width: '30%' height: '30%'}}/><br/>${'Подождите, выполняется операция в NCALayer...'}`
	overlay.style.display = 'block'
	overlay.style.position = 'fixed'
	overlay.style.top = '0'
	overlay.style.left = '0'
	overlay.style.width = '100%'
	overlay.style.height = '100%'
	overlay.style.background = 'rgba(0, 0, 0, 0.5)'
	overlay.style.color = '#fff'
	overlay.style.textAlign = 'center'
	overlay.style.paddingTop = '20%'
	overlay.style.zIndex = '5000'
}

export function unblockScreen() {
	const overlay = document.getElementById('blockUIOverlay')
	if (overlay) {
		overlay.remove()
	}
}

export class DigitalSign {
	constructor() {
		this._websocket = null
		this._connecting = false

		this.payload = initialState
	}

	async #open() {
		if (!this._websocket || this._websocket.readyState === WebSocket.CLOSED) {
			this._connecting = true
			this._websocket = new WebSocket(url)

			this.payload = {
				...initialState,
				loading: true
			}

			return new Promise((resolve, reject) => {
				this._websocket.onopen = (e) => {
					this._connecting = false
					resolve()
				}

				this._websocket.onclose = (e) => {
					onError(e)
				}

				this._websocket.onerror = (e) => {
					onError(e)
				}

				const onError = (e) => {
					this._connecting = false
					this.payload = {
						...initialState,
						failed: true,
					}
					reject(new Error(t('ncalayer_info')))
				}
			})
		} else {
			return Promise.resolve()
		}
	}

	handleClear() {
		if (this._websocket) {
			this._websocket.close()
			this._websocket = null
			this.payload = initialState
		}
	}

	async handleSend(xml) {
		await this.#open()

		const signXml = {
			module: 'kz.gov.pki.knca.commonUtils',
			method: 'signXml',
			args: ['PKCS12', 'SIGNATURE', xml, '', ''],
		}

		return new Promise((resolve, reject) => {
			if (this._websocket.readyState !== WebSocket.OPEN) {
				return reject(new Error('websocket closed'))
			}

			blockScreen()

			this._websocket.send(JSON.stringify(signXml))

			const handleResponse = (event) => {
				this._websocket.removeEventListener('message', handleResponse)

				this._websocket.onmessage = async (event) => {
					const result = JSON.parse(event.data)
					if (result) {
						const data = {
							code: result['code'],
							message: result['message'],
							responseObject: result['responseObject'],
						}

						unblockScreen()

						this.handleClear()

						if(data['code'] == 500){
							this.payload = {
								...initialState,
								failed: true,
							}
						} else if (data['code'] == 200){
							resolve(data['responseObject'])
							this.payload = {
								...initialState,
								success: true,
								data: data['responseObject']
							}
						}
					}
				}
			}

			this._websocket.addEventListener('message', handleResponse)

			this._websocket.onerror = (error) => {
				this.payload = {
					...initialState,
					failed: true,
				}
				this.handleClear()
				reject(new Error('WebSocket error: ' + error))
			}
		})
	}
}