import { useEffect } from 'react'
import { Redirect, useHistory } from 'react-router-dom'

const OS = {
	android: 'android',
	ios: 'ios',
}

const getOs = () => {
	const userAgent = navigator.userAgent || navigator.vendor || window.opera

	const android = (/android/i.test(userAgent))
	const ios = (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)

	if(ios){
		return OS.ios
	}

	if(android){
		return OS.android
	}

	return
}

export const AppDownload = () => {
	const history = useHistory()

	const google_play = process.env.REACT_APP_MOBILE_BANNER_GOOGLE
	const app_store = process.env.REACT_APP_MOBILE_BANNER_APPLE

	useEffect(() => {
		const os = getOs()

		const link = {
			[OS.ios]: app_store,
			[OS.android]: google_play,
		}

		if(link[os]){
			window.location.href = link[os]
		} else {
			history.goBack()
		}

	}, [])

	return null
}