import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Title } from '@/ui'
import styles from './View.module.scss'
import { Edit, Trash } from '@app/icons'
import { PositionInformation } from './PositionInformation/PositionInformation'
import { getCurrentLanguage } from '@/i18next'
import { useSelector } from 'react-redux'

export const PositionCard = ({ onClose, onEdit, onRemove, positionRowSelectMenu, history }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const optionLang = lang === 'rus' ? 'nameRu' : 'nameKz'
	const {staffingGrants} = useSelector((state) => ({
		staffingGrants: state.administration.staffingGrants,
	}))
	return (
		<div>
			<Title color="black">{positionRowSelectMenu.establishedPost[optionLang]}</Title>
			{!history ? (
				staffingGrants?.data?.stRegistration &&
				<div className={styles.actions}>
					<Button
						onClick={onEdit}
						buttonClassName={styles.button}
						textColor="green"
						borderColor="green"
						transparent
						border
					>
						<Edit className={styles.iconGreen} />
						{t('edit_button')}
					</Button>
					<Button onClick={onRemove} buttonClassName={styles.button} type="bare">
						<Trash className={styles.iconGrey} />
						{t('remove')}
					</Button>
				</div>
			) : null}
			<div className={styles.informationSections}>
				<section>
					<PositionInformation position={positionRowSelectMenu}></PositionInformation>
				</section>
			</div>
			<div className={styles.close}>
				<Button onClick={onClose}>{t('close')}</Button>
			</div>
		</div>
	)
}
