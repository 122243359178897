import React from 'react'
import { Title } from '@/ui'
import TabsUnstyled from '@mui/base/Tabs'
import TabsList from '@/ui/atoms/tabsList'
import Tab from '@/ui/atoms/tab'
import TabPanel from '@mui/base/TabPanel'
import { Table } from './components/Table/Table'
import { Calendar } from './components/Calendar/Calendar'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { employerProfileSelectors } from '@features/employerProfile/model'
import { Transfer } from '@pages/PersonalAffairs/Tabs/SocialPaymentMainTab/components/Transfer/Transfer'
const SocialPaymentMainTab = () => {
	const { t } = useTranslation()
	const activeCompany = useSelector(employerProfileSelectors.activeCompany)
	const currentYear = new Date().getFullYear()
	const years = Array.from({ length: currentYear - 2023 }, (_, i) => 2024 + i)
	const items = years.map((year) => ({ title: year.toString(), action: year.toString() }))
	return (
		<>
			<section>
				<TabsUnstyled defaultValue={0}>
					<TabsList>
						<Tab value={0}>{t('payment.calendarTab')}</Tab>
						<Tab value={1}>{t('payment.paymentTab')}</Tab>
						<Tab value={2}>{t('payment.transferTab')}</Tab>
					</TabsList>
					<TabPanel value={0}>
						<Calendar activeCompany={activeCompany} itemsYears={items} />
					</TabPanel>
					<TabPanel value={1}>
						<Table activeCompany={activeCompany} />
					</TabPanel>
					<TabPanel value={2}>
						<Transfer activeCompany={activeCompany} itemsYears={items} />
					</TabPanel>
				</TabsUnstyled>
			</section>
		</>
	)
}

export default SocialPaymentMainTab
