import { api } from '@/shared'
import { message } from 'antd'
import { sickLeaveActions } from '@/entity/sickLeave/index'

export const getSickData = (payload) => {
	return async (dispatch) => {
		try {
			dispatch(sickLeaveActions.getSickDataAction())

			const {type, ...data} = payload

			let body = {
				params: data.params,
				payload: {
					type: type
				}
			}

			const res = await api.sickLeave.getSickData(body)

			if (res) {
				dispatch(sickLeaveActions.getSickDataActionDone(res))
			}

		} catch (error) {
			message.error(error.response?.data?.message)

			dispatch(sickLeaveActions.getSickDataActionFail())
		}
	}
}

export const getActualContract = (payload) => {
	return async (dispatch) => {
		try {
			dispatch(sickLeaveActions.getActualContractAction())

			let body = {
				data: payload
			}

			const res = await api.sickLeave.getActualContract(body)

			if(res){
				dispatch(sickLeaveActions.getActualContractActionDone(res?.map((item, index) => ({
					...item,
					key: `${index}-${item.bin}`
				}))))
			}
		} catch (error){
			message.error(error.response?.data?.message)

			dispatch(sickLeaveActions.getActualContractActionFail())
		}
	}
}

export const getEmployeePhone = (payload) => {
	const {data, onSuccess} = payload

	return async (dispatch) => {
		try {
			dispatch(sickLeaveActions.getEmployeePhoneAction())

			let body = {
				params: {
					iin: data
				}
			}

			const res = await api.sickLeave.getEmployeePhone(body)

			if(res){
				onSuccess(res)
				dispatch(sickLeaveActions.getEmployeePhoneActionDone(res))
			} else {
				message.error('Phone Number is empty')
				dispatch(sickLeaveActions.getEmployeePhoneActionFail())
			}

		} catch (error){
			message.error(error.response?.data?.message)

			dispatch(sickLeaveActions.getEmployeePhoneActionFail())
		}
	}
}

export const sendSms = (payload) => {
	const {data, onSuccess} = payload

	return async (dispatch) => {
		try {
			dispatch(sickLeaveActions.sendSmsAction())

			let body = {
				data: data
			}

			const res = await api.sickLeave.sendSms(body)

			onSuccess(res)
			dispatch(sickLeaveActions.sendSmsActionDone(res))
		} catch (error){
			message.error(error.response?.data?.message)

			dispatch(sickLeaveActions.sendSmsActionFail())
		}
	}
}

export const setSickLeave = (payload) => {
	const {data, onSuccess} = payload

	return async (dispatch) => {
		try {
			dispatch(sickLeaveActions.setSickLeaveAction())

			let body = {
				data: data
			}

			const res = await api.sickLeave.setSickLeave(body)

			onSuccess()
			dispatch(sickLeaveActions.setSickLeaveActionDone(res))

		} catch (error){
			message.error(error.response?.data?.message)

			dispatch(sickLeaveActions.setSickLeaveActionFail())
		}
	}
}