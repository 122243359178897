import { Logo as LogoImage } from '@/app/images'
import { Link } from 'react-router-dom'
import qrCode from '../../../app/images/qr-code.png'
import appStoreRu from '../../../assets/home/app-store-ru.svg'
import googlePlay from '../../../assets/home/google-apps-ru.svg'
import logoInstagram from '../../../assets/home/instagram.svg'
import { useTranslation } from 'react-i18next'
import LangFooter from '@/components/LangFooter'
import { useEffect, useMemo, useState } from 'react'
import { ModalFeedback } from '@/components/ModalFeedback/ModalFeedback'
import { getCurrentLanguage } from '@/i18next'
import { useSelector } from 'react-redux'

import { mainSelectors } from '@app/store/main'
import { userSelectors } from '@/entity'
import cl from 'classnames'

const Footer = () => {
	const isSuccessSend = useSelector(mainSelectors.isSuccessSend)
	const activeRole = useSelector(userSelectors.activeRole)
	const companies = useSelector(userSelectors.employerCompanies)
	const user = useSelector(userSelectors.user)
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const [modalShow, setModalShow] = useState(false)
	let thisYear = new Date().getFullYear()
	const [urlAuth, setUrlAuth] = useState()
	const [refEnbek, setRefEnbek] = useState()
	const [refSkills, setRefSkills] = useState()
	const [refBusiness, setBusiness] = useState()
	const [refCareer, setCareer] = useState()
	const [refTerms, setRefTerms] = useState()
	const [refContract, setRefContract] = useState('/contracts')
	const [instagramLink, setInstagramLink] = useState()
	useMemo(() => setInstagramLink(process.env.REACT_APP_INSTAGRAM_LINK), [])

	useEffect(() => {
		if (isSuccessSend) setModalShow(false)
	}, [isSuccessSend])

	useMemo(
		() =>
			lang === 'rus'
				? [
						setUrlAuth(process.env.REACT_APP_LOGIN + 'ru' + process.env.REACT_APP_REDIRECT_ROLE),
						setRefEnbek(process.env.REACT_APP_REDIRECT_ENBEK + 'ru'),
						setRefSkills(process.env.REACT_APP_REDIRECT_SKILLS + 'ru'),
						setBusiness(process.env.REACT_APP_REDIRECT_BUSINESS),
						setCareer(process.env.REACT_APP_REDIRECT_CAREER + 'ru'),
						setRefTerms(process.env.REACT_APP_REDIRECT_ENBEK + process.env.REACT_APP_REDIRECT_TERMS_RU),
				  ]
				: [
						setUrlAuth(process.env.REACT_APP_LOGIN + 'kk' + process.env.REACT_APP_REDIRECT_ROLE),
						setRefEnbek(process.env.REACT_APP_REDIRECT_ENBEK + 'kk'),
						setRefSkills(process.env.REACT_APP_REDIRECT_SKILLS + 'kk'),
						setBusiness(process.env.REACT_APP_REDIRECT_BUSINESS),
						setCareer(process.env.REACT_APP_REDIRECT_CAREER + 'kk'),
						setRefTerms(process.env.REACT_APP_REDIRECT_ENBEK + process.env.REACT_APP_REDIRECT_TERMS_KZ),
				  ],
		[lang]
	)

	useMemo(
		() =>
			(activeRole === 'ROLE_EMPLOYER' && !companies.length) || (activeRole === 'ROLE_EMPLOYEE' && !user?.iin)
				? setRefContract('/reference-information/3')
				: setRefContract('/contracts'),
		[activeRole, companies.length]
	)

	const handleClickFeedback = () => {
		setModalShow(true)
	}

	const handleClose = () => {
		setModalShow(false)
	}

	const ecosystemArray = [
		{
			label: t('electronic_labor_exchange'),
			link: refEnbek,
		},
		{
			label: 'Skills.Enbek',
			link: refSkills,
		},
		{
			label: 'Business.Enbek',
			link: refBusiness,
		},
		{
			label: 'Career.Enbek',
			link: refCareer,
		},
		{
			label: 'Migration.Enbek',
			link: process.env.REACT_APP_REDIRECT_MIGRATION,
		},
		{
			label: 'Mansap.Enbek',
			link: process.env.REACT_APP_REDIRECT_MANSAP,
		}
	]

	return (
		<div className="footer">
			<div className="container-footer">
				<ModalFeedback visible={modalShow} onClose={handleClose} />
				<div className="footer-wrapper">
					<div className="footer-support">
						<div className="block-left">
							<span>{t('difficulties')}</span>
							<div className="block-left_contact">
								<p>{t('contact_technical_support')}</p>
							</div>
						</div>
						<div className="block-right">
							<div className="greenBtn" onClick={handleClickFeedback}>
								<button>{t('send_message')}</button>
							</div>
						</div>
					</div>
					<div className="footer-menu">
						<div className="footer-menu-logo">
							<div className="footer-menu-logo-soc">
								<Link to={'/'}>
									<img src={LogoImage} alt="enbek" />
								</Link>
								<p>{t('digital_ecosystem')}</p>
								<a href={instagramLink} target="_blank" rel="noopener noreferrer">
									<img src={logoInstagram} alt="instagram"></img>
								</a>
								<div className="footer-menu-logo-number">
									<span>1414</span>
									<p>{t('conversations_kz')}</p>
									<p>{t('voice_robot')}</p>
								</div>
							</div>
						</div>
						<div className="footer-menu-general">
							<span>{t('main')}</span>
							{!user ? (
								<p>
									<a className={'link'} href={urlAuth}>
										{t('employment_contract')}
									</a>
								</p>
							) : (
								<p>
									<a className={'link'} href={refContract}>
										{t('employment_contract')}
									</a>
								</p>
							)}
							<p>
								<Link to={'/forms'} className={'link'}>
									{t('online_consultant')}
								</Link>
							</p>
							<p>
								<Link to={'/reference-information/2'} className={'link'}>
									{t('services')}
								</Link>
							</p>
							<p>
								<Link to={'/reference-information/3'} className={'link'}>
									{t('questions')}
								</Link>
							</p>
						</div>
						<div className="footer-menu-portal">
							<span>{t('portal')}</span>
							<p>
								<Link to={'/about-project'} className={'link'}>
									{t('about_project')}
								</Link>
							</p>
							<p>
								<Link to={'/for-employers'} className={'link'}>
									{t('for_employer')}
								</Link>
							</p>
							<p>
								<Link to={'/for-workers'} className={'link'}>
									{t('for_worker')}
								</Link>
							</p>
							<p>
								<a href={refTerms} target="_blank" rel="noopener noreferrer">
									{' '}
									{t('terms_use')}
								</a>
							</p>
							<p>
								<Link to={'/reference-information/1'} className={'link'}>
									{t('helpTab')}
								</Link>
							</p>
						</div>
						<div className="footer-menu-frame_logo">
							<div>
								<span>
									{t('digital_ecosystem_employment')}
								</span>
								{ecosystemArray.map((item, index) => (
									<p key={index}>
										<a href={item.link} className={cl('link')}>
											{item.label}
										</a>
									</p>
								))}
							</div>
							<div className={cl('footer-menu-frame_logo-app_button', 'mt-4')}>
								<img src={qrCode} alt="qr-code" width={102} height={102}/>

								<span>
									{t('app_download_link')}
								</span>
							</div>
						</div>
					</div>
					<div className="footer-menu-mobile">
						<div className="footer-menu-mobile-left">
							<div className="footer-menu-mobile-block">
								<Link to={'/'}>
									<img src={LogoImage} alt="enbek" />
								</Link>
								<p>{t('digital_ecosystem')}</p>
								<a href={instagramLink} target="_blank" rel="noopener noreferrer">
									<img src={logoInstagram} alt="instagram"></img>
								</a>
							</div>
							<div className="footer-menu-general">
								<span>{t('main')}</span>
								{!user ? (
									<p>
										<a className={'link'} href={urlAuth}>
											{t('employment_contract')}
										</a>
									</p>
								) : (
									<p>
										<Link to={'/contracts'} className={'link'}>
											{t('employment_contract')}
										</Link>
									</p>
								)}
								<p>
									<Link to={'/forms'} className={'link'}>
										{t('consultant')}
									</Link>
								</p>
								<p>
									<Link to={'/reference-information/2'} className={'link'}>
										{t('services')}
									</Link>
								</p>
								<p>
									<Link to={'/reference-information/3'} className={'link'}>
										{t('questions')}
									</Link>
								</p>
							</div>
						</div>
						<div className="footer-menu-mobile-right">
							<div className="footer-menu-mobile-block">
								<span>1414</span>
								<p>{t('conversations_kz')}</p>
								<p>{t('voice_robot')}</p>
							</div>
							<div className="footer-menu-portal">
								<span>{t('portal')}</span>
								<p>
									<Link to={'/about-project'} className={'link'}>
										{t('about_project')}
									</Link>
								</p>
								<p>
									<Link to={'/for-employers'} className={'link'}>
										{t('for_employer')}
									</Link>
								</p>
								<p>
									<Link to={'/for-workers'} className={'link'}>
										{t('for_worker')}
									</Link>
								</p>
								<p>
									<a href={refTerms} target="_blank" rel="noopener noreferrer">
										{' '}
										{t('terms_use')}
									</a>
								</p>
								<p>
									<Link to={'/reference-information/1'} className={'link'}>
										{t('helpTab')}
									</Link>
								</p>
							</div>
						</div>
					</div>
					<div className="footer-disable">
						<div>
							<span>
								{t('digital_ecosystem_employment')}
							</span>
							{ecosystemArray.map((item, index) => (
								<p key={index}>
									<a href={item.link} className={cl('link')}>
										{item.label}
									</a>
								</p>
							))}
						</div>
						<div className={cl('footer-menu-frame_logo-app_button', 'mt-4')}>
							<img src={qrCode} alt="qr-code" width={102} height={102} />

							<span>
									{t('app_download_link')}
							</span>
						</div>
					</div>
					<div className="footer-line" />
					<div className="footer-copyright">
						<p>
							&copy; 2020 - {thisYear}, {t('crtr')}
						</p>
						<LangFooter />
					</div>
				</div>
			</div>
		</div>
	)
}

export default Footer
