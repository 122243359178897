import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { handbookThunks } from '@/entity/handbook'
import { Title } from '@/ui'
import { ConditionsPicker } from './ConditionsPicker'
import styles from './AdditionalContractForm.module.scss'
import { contractsSelectors } from '@/entity'
import { ValidationSchema } from '@features/contracts/additionalContracts/form/validationSchema'
import { contractActionsSelectors } from '@features/contracts/model'
import { Form } from './Form'

const formDefaultValues = {
	contractNum: null,
	registerDate: null,
	dateFrom: null,
	dateTo: null,
	dpartTimeCode: null,
	dpositionCode: null,
	destablishedPostCode: null,
	establishedPost: null,
	dcontractDateCode: null,
	contractEndDate: null,
	dworkingHoursCode: null,
	tariffRate: null,
	dremoteWorkCode: null,
	ddistrictCode: null,
	dregionCode: null,
	dlocalityCode: null,
	dcountryCode: null,
	workingPlace: null,
	otherConditions: null,
	files: [],
	employeeRequest: null,
	employeeConsent: null,
	employerApprove: null,
	employeeAccountNumber: '',
}

export const AdditionalContractForm = ({
	onCancelEditing,
	onSubmit,
	additionalContractTransfer,
	isEdit = false,
	document,
	setDoc,
	nextPage,
}) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [shortList] = useState(false)
	useEffect(() => {
		dispatch(handbookThunks.getContractTerms(shortList))
	}, [dispatch, shortList])
	const [conditions, setConditions] = useState(null)
	const { positionsByKnz } = useSelector(contractActionsSelectors.options)
	const contract = useSelector(contractsSelectors.contract)
	const additionalAgreement = useSelector(contractsSelectors.additionalContract)
	const [isLocal, setIsLocal] = useState(false)
	const validationSchema = useMemo(
		() => ValidationSchema({ ...conditions, econtractFound: contract?.econtractFound }),
		[conditions]
	)
	const [showCheckbox, setShowCheckbox] = useState(true)
	const [showFileUpload, setShowFileUpload] = useState(false)
	const [filesMiddleWare, setFilesMiddleWare] = useState([])

	const attachHarmfulConditionsFiles = (values) => {
		setFilesMiddleWare(values)
	}

	const handleSubmit = (_data) => {
		let sendFileArray = []
		let harmfulFields = []
		if (_data.dworkingConditionsCode && _data.dworkingConditionsCode !== '0') {
			if (filesMiddleWare.length > 0) {
				sendFileArray = filesMiddleWare.map((file) => ({
					id: file.id,
				}))
			}
		} else {
			harmfulFields = ['dharmfulConditionsTypeCode']
		}
		// Фильтрация данных, исключая ненужные поля
		const excludedFields = ['requiredList', 'dpositionCodeList', 'positionParents']

		let filteredData = Object.keys(_data)
			.filter((key) => !excludedFields.some((excludedField) => key.startsWith(excludedField)))
			.filter((key) => !harmfulFields.some((harmfulField) => key.includes(harmfulField)))
			.reduce((obj, key) => {
				obj[key] = _data[key]
				return obj
			}, {})

		// Включение массива файлов в объект данных
		const data = { ...filteredData, files: sendFileArray || [] }

		let result = true
		if (conditions?.contractPosition) {
			result = Boolean(positionsByKnz.find(({ code }) => code === data?.dpositionCode))
		}

		const fields = {
			contractJobLocation: [
				'dremoteWorkCode',
				'dcountryCode',
				'ddistrictCode',
				'dregionCode',
				'dlocalityCode',
				'workingPlace',
			],
			contractJobType: ['dpartTimeCode'],
			contractPosition: ['dpositionCode', 'destablishedPostCode', 'establishedPost'],
			contractTerm: ['dcontractDateCode', 'contractEndDate'],
			contractWorkingHours: ['dworkingHoursCode', 'tariffRate'],
			contractOther: ['otherConditions'],
		}

		for (const key in fields) {
			if (!conditions[key]) {
				for (let i = 0; i < fields[key].length; i++) {
					const element = fields[key][i]
					data[element] = null
				}
			}
		}

		if (result && !contract.fromFile) {
			onSubmit({ ...data, paramMap: { contractNum: data.contractNum, dateFrom: data.registerDate } }, isLocal)
		} else if (contract.fromFile) {
			setShowFileUpload(true)
			if (document) {
				nextPage({ contractNum: data.contractNum, dateFrom: data.registerDate, ...data })
			}
		}
	}

	return (
		<>
			<div className={styles.mainInfoRow}>
				<div className={styles.row}>
					<p className={styles.dataCol}>{t('worker_iin')}:</p>
					<p className={styles.greyText}>{contract?.iin || additionalContractTransfer?.iin}</p>
				</div>
				<div className={styles.row}>
					<p className={styles.dataCol}>{t('personal_short_information')}:</p>
					<p className={styles.greyText}>{contract?.fullName || additionalContractTransfer?.fullName}</p>
				</div>
			</div>

			<Title>{t('additional_contract_info')}</Title>
			<Formik
				initialValues={isEdit ? additionalAgreement : formDefaultValues}
				onSubmit={handleSubmit}
				validationSchema={validationSchema}
			>
				<Form
					setIsLocal={setIsLocal}
					setDoc={setDoc}
					showCheckbox={showCheckbox}
					conditions={conditions}
					additionalContractTransfer={additionalContractTransfer}
					showFileUpload={showFileUpload}
					contract={contract}
					onCancelEditing={onCancelEditing}
					additionalAgreement={additionalAgreement}
					attachHarmfulConditionsFiles={attachHarmfulConditionsFiles}
					isEdit={isEdit}
				/>
			</Formik>
			{!conditions && (
				<ConditionsPicker
					isTransfer={additionalContractTransfer}
					onSaveChoice={setConditions}
					onCancelEditing={onCancelEditing}
					isEdit={isEdit}
					setShowCheckbox={setShowCheckbox}
				/>
			)}
		</>
	)
}
