import * as types from './types'

export const actions = {
	getBranchesAction: (payload) => ({
		type: types.GET_BRANCHES,
	}),

	getBranchesActionDone: (payload) => ({
		type: types.GET_BRANCHES_DONE,
		payload,
	}),

	getBranchesActionFail: (payload) => ({
		type: types.GET_BRANCHES_FAIL,
	}),


	setSignAction: (payload) => ({
		type: types.SET_SIGN,
	}),

	setSignActionDone: (payload) => ({
		type: types.SET_SIGN_DONE,
		payload,
	}),

	setSignActionFail: (payload) => ({
		type: types.SET_SIGN_FAIL,
	}),


	setGrandAction: (payload) => ({
		type: types.SET_GRANTS,
	}),

	setGrandActionDone: (payload) => ({
		type: types.SET_GRANTS_DONE,
		payload,
	}),

	setGrandActionFail: (payload) => ({
		type: types.SET_GRANTS_FAIL,
	}),


	sendSignedXml: (payload) => ({
		type: types.SEND_SIGNED_XML,
	}),

	sendSignedXmlDone: (payload) => ({
		type: types.SEND_SIGNED_XML_DONE,
		payload,
	}),

	sendSignedXmlFail: (payload) => ({
		type: types.SEND_SIGNED_XML_FAIL,
	}),


	getStaffingAdminOrgsAction: (payload) => ({
		type: types.GET_STAFFING_ADMINISTRATION_ORGS,
	}),

	getStaffingAdminOrgsActionDone: (payload) => ({
		type: types.GET_STAFFING_ADMINISTRATION_ORGS_DONE,
		payload,
	}),

	getStaffingAdminOrgsActionFail: (payload) => ({
		type: types.GET_STAFFING_ADMINISTRATION_ORGS_FAIL,
	}),


	setStaffingAdminOrgAction: (payload) => ({
		type: types.SET_STAFFING_ADMINISTRATION_ORG,
	}),

	setStaffingAdminOrgActionDone: (payload) => ({
		type: types.SET_STAFFING_ADMINISTRATION_ORG_DONE,
		payload,
	}),

	setStaffingAdminOrgActionFail: (payload) => ({
		type: types.SET_STAFFING_ADMINISTRATION_ORG_FAIL,
	}),


	getStaffingGrantsAction: (payload) => ({
		type: types.GET_STAFFING_GRANTS,
	}),

	getStaffingGrantsActionDone: (payload) => ({
		type: types.GET_STAFFING_GRANTS_DONE,
		payload,
	}),

	getStaffingGrantsActionFail: (payload) => ({
		type: types.GET_STAFFING_GRANTS_FAIL,
	}),
}
