import { staffingActions } from '@/entity/staffing'
import { api, getFromLocalStorage } from '@/shared'
import { message } from 'antd'
import { staffActions } from '@/entity'

export const getActualStaff = () => {
	return async (dispatch) => {
		try {
			dispatch(staffingActions.toggleLoading(true))
			const activeStaffBranch = await getFromLocalStorage('activeStaffBranch')

			let body = {
				params: {
					currentStaffBranchBin: activeStaffBranch
				},
			}
			const staff = await api.staff.getActualStaff(body)
			dispatch(staffingActions.setStaffing(staff))
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleSuccess(true))
		} catch (error) {
			dispatch(staffingActions.toggleLoading(false))
			if (error.response) {
				dispatch(staffingActions.toggleError(error))
				message.error(
					'Ошибка получения данных:' +
						(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
					10
				)
			}
		} finally {
			dispatch(staffingActions.toggleSuccess(false))
		}
	}
}

export const getActualXml = (orgId) => {
	return async (dispatch) => {
		try {
			const activeStaffBranch = await getFromLocalStorage('activeStaffBranch')

			dispatch(staffingActions.toggleLoading(true))

			let body = {
				params: {
					currentStaffBranchBin: activeStaffBranch
				},
				payload: {
					orgId
				}
			}
			const xmlData = await api.staff.getActualXml(body)
			dispatch(staffingActions.setStaffXml(xmlData))
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleSuccess(true))
		} catch (error) {
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleError(error))
			message.error(
				'Ошибка получения данных для подписи:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffingActions.toggleSuccess(false))
		}
	}
}

export const sendActualSignStaffing = (data) => {
	return async (dispatch) => {
		try {
			dispatch(staffingActions.toggleLoading(true))
			const activeStaffBranch = await getFromLocalStorage('activeStaffBranch')
			let body = {
				params: {
					currentStaffBranchBin: activeStaffBranch
				},
				data: { data }
			}
			const response = await api.staff.sendActualSignStaffing(body)
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleSuccess(true))
			message.success('Запрос новой версии штатного расписания успешно отправлен!', 5)
		} catch (error) {
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleError(error))
			message.error(
				'Ошибка отправки данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffingActions.toggleSuccess(false))
			dispatch(staffingActions.setStaffXml(null))
		}
	}
}

export const saveWorkPlace = (data, wpId) => {
	return async (dispatch) => {
		try {
			dispatch(staffingActions.toggleLoading(true))
			const activeStaffBranch = await getFromLocalStorage('activeStaffBranch')
			let body = {
				params: {
					currentStaffBranchBin: activeStaffBranch
				},
				payload: {wpId},
				data: data
			}
			const response = await api.staff.saveWorkPlace(body)
			dispatch(staffingActions.update_WPlace_Employee(response.wplaceEmployee)) // обновляем wplaceEmployeeList
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleSuccess(true))
			message.success('Назначение на должность успешно сохранено!', 5)
		} catch (error) {
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleError(error))
			message.error(
				'Ошибка сохранения места работы:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffingActions.toggleSuccess(false))
		}
	}
}

export const getWorkPlaceList = (wpId) => {
	return async (dispatch) => {
		try {
			dispatch(staffingActions.toggleLoading(true))
			const activeStaffBranch = await getFromLocalStorage('activeStaffBranch')
			let body = {
				params: {
					currentStaffBranchBin: activeStaffBranch
				},
				payload: {wpId}
			}
			let workPlaceList = await api.staff.getWorkPlaceList(body)
			// Проверка если workPlaceList пуст
			workPlaceList = workPlaceList ? workPlaceList : []
			dispatch(staffingActions.setWPlaceEmployee(workPlaceList))
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleSuccess(true))
		} catch (error) {
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleError(error))
			message.error(
				'Ошибка получения назначенных на должность:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffingActions.toggleSuccess(false))
		}
	}
}

export const deleteWorkPlace = (data, wpId) => {
	return async (dispatch) => {
		try {
			dispatch(staffingActions.toggleLoading(true))
			const activeStaffBranch = await getFromLocalStorage('activeStaffBranch')
			let body = {
				params: {
					currentStaffBranchBin: activeStaffBranch
				},
				data: data,
				payload: {wpId}
			}
			const response = await api.staff.deleteWorkPlace(body)
			if (response === '') {
				dispatch(staffingActions.delete_WPlace_Employee(data.externalId))
				dispatch(staffingActions.toggleLoading(false))
				dispatch(staffingActions.toggleSuccess(true))
				message.success('Сотрудник успешно удален!', 5)
			}
		} catch (error) {
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleError(error))
			message.error(
				'Ошибка снятие с должности:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffingActions.toggleSuccess(false))
		}
	}
}

export const getEmployeeListXml = () => {
	return async (dispatch) => {
		try {
			dispatch(staffingActions.toggleLoading(true))
			const activeStaffBranch = await getFromLocalStorage('activeStaffBranch')
			let body = {
				params: {
					currentStaffBranchBin: activeStaffBranch
				},
			}
			const employeeListXml = await api.staff.getEmployeeListXml(body)
			dispatch(staffingActions.setEmployeeXml(employeeListXml))
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleSuccess(true))
		} catch (error) {
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleError(error))
			message.error(
				'Ошибка получения списка сотрудников для подписания:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffingActions.toggleSuccess(false))
		}
	}
}

export const sendEmployeeList = (data) => {
	return async (dispatch) => {
		try {
			dispatch(staffingActions.toggleLoading(true))
			const activeStaffBranch = await getFromLocalStorage('activeStaffBranch')
			let body = {
				params: {
					currentStaffBranchBin: activeStaffBranch
				},
				data: data,
			}
			const response = await api.staff.sendEmployeeListSign(body)
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleSuccess(true))
			message.success('Назначения успешно отправлены!', 5)
		} catch (error) {
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleError(error))
			message.error(
				'Ошибка отправки данных: ' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffingActions.toggleSuccess(false))
			dispatch(staffingActions.setEmployeeXml(null))
		}
	}
}

export const deleteWorkPlaceEsutd = (wpEmployeeId) => {
	return async (dispatch) => {
		try {
			dispatch(staffingActions.toggleLoading(true))
			const activeStaffBranch = await getFromLocalStorage('activeStaffBranch')
			let body = {
				params: {
					currentStaffBranchBin: activeStaffBranch
				},
				payload: {wpEmployeeId},
			}
			const xmlDel = await api.staff.delXMLEmployee(body)
			dispatch(staffingActions.setDelEmployeeXml(xmlDel))
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleSuccess(true))
		} catch (error) {
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleSuccess(false))
			message.error(
				'Ошибка удаления сотрудника:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffingActions.toggleSuccess(false))
		}
	}
}

export const sendDelWorkPlaceEsutd = (externalId, signXml) => {
	return async (dispatch) => {
		try {
			dispatch(staffingActions.toggleLoading(true))
			const activeStaffBranch = await getFromLocalStorage('activeStaffBranch')
			let body = {
				params: {
					currentStaffBranchBin: activeStaffBranch
				},
				data: { signXml },
			}

			const response = await api.staff.sendDelWorkPlaceEsutd(body)
			if (response === '') {
				dispatch(staffingActions.delete_WPlace_Employee(externalId))
				dispatch(staffingActions.toggleLoading(false))
				dispatch(staffingActions.toggleSuccess(true))
				message.success('Сотрудник успешно удален!', 5)
			}
		} catch (error) {
			dispatch(staffingActions.toggleError(error))
			dispatch(staffingActions.toggleLoading(false))
			message.error(
				'Ошибка отправки данных: ' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffingActions.toggleSuccess(false))
			dispatch(staffingActions.setDelEmployeeXml(null))
		}
	}
}

export const saveWorkPlaceEsutd = (data) => {
	return async (dispatch) => {
		try {
			dispatch(staffingActions.toggleLoading(true))
			const activeStaffBranch = await getFromLocalStorage('activeStaffBranch')
			let body = {
				params: {
					currentStaffBranchBin: activeStaffBranch
				},
				data: data,
			}
			const xmlEdit = await api.staff.saveXMLEmployee(body)
			dispatch(staffingActions.setEditEmployeeXml(xmlEdit))
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleSuccess(true))
		} catch (error) {
			dispatch(staffingActions.toggleError(error))
			message.error(
				'Ошибка получения данных для подписания' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffingActions.toggleSuccess(false))
		}
	}
}

export const sendEditWorkPlaceEsutd = (wpId, data) => {
	return async (dispatch) => {
		try {
			const activeStaffBranch = await getFromLocalStorage('activeStaffBranch')

			let body = {
				params: {
					currentStaffBranchBin: activeStaffBranch
				},
			}
			dispatch(staffingActions.toggleLoading(true))
			const response = await api.staff.sendEditWorkPlaceEsutd({
				...body,
				data: data,
			})
			if (response === '') {
				let workPlaceList = await api.staff.getWorkPlaceList({
					...body,
					payload: {wpId}
				})
				// Проверка если workPlaceList пуст
				workPlaceList = workPlaceList ? workPlaceList : []
				dispatch(staffingActions.setWPlaceEmployee(workPlaceList))
				dispatch(staffingActions.setEmployeeXml(workPlaceList))
				dispatch(staffingActions.setEditEmployeeXml(null))
			}
			dispatch(staffingActions.toggleLoading(false))
			dispatch(staffingActions.toggleSuccess(true))
			message.success('Изменения успешно отправлены!', 5)
		} catch (error) {
			dispatch(staffActions.toggleLoading(false))
			dispatch(staffActions.toggleError(error))
			dispatch(staffingActions.setEditEmployeeXml(null))
			message.error(
				'Ошибка добавления данных:' +
					(error && error.response && error.response.data ? error.response.data.message : 'Неизвестная ошибка'),
				10
			)
		} finally {
			dispatch(staffingActions.toggleSuccess(false))
		}
	}
}
