import styles from './ReviewInfoWarning.module.scss'
import { InfoGreen } from '@app/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import cl from 'classnames'

export const ReviewInfoWarning = ({title, text}) => {
	const { t } = useTranslation()

	return (
		<div className={styles.information}>
			<InfoGreen className={styles.hintIcon} />
			<div className={styles.textContainer}>
				<div className={cl(styles.title, styles.text)}>{title}</div>
				<div className={cl(styles.label, styles.text)}>{text}</div>
			</div>
		</div>
	)
}