import React, { memo } from 'react'
import styles from './AdministrationInfoWarning.module.scss'
import { Info } from '@app/icons'
import { useTranslation } from 'react-i18next'

export const AdministrationInfoWarning = memo(({text}) => {
	const { t } = useTranslation()

	return (
		<div className={styles.information}>
			<Info className={styles.hintIcon} />
			<div className={styles.tableText}>{text}</div>
		</div>
	)
})