import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

export const StyledSwitch = styled((props) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props}/>
))(({ theme }) => ({
	width: 44,
	height: 26,
	padding: 0,
	margin: '0 !important',
	'& .MuiSwitch-switchBase': {
		padding: 0,
		margin: 2,
		transitionDuration: '300ms',
		'&.Mui-checked': {
			transform: 'translateX(18px)',
			color: '#fff',
			'& .MuiSwitch-thumb:before': {
				backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="8" viewBox="0 0 10 8"><path fill="${encodeURIComponent(
					'#17B67C',
				)}" d="M9.66437 0.252601C10.0772 0.619519 10.1143 1.25159 9.74742 1.66437L4.41409 7.66437C4.22432 7.87786 3.95232 8.00001 3.66668 8.00001C3.38104 8.00001 3.10904 7.87786 2.91927 7.66437L0.252601 4.66437C-0.114317 4.25159 -0.077136 3.61952 0.335647 3.2526C0.74843 2.88568 1.3805 2.92286 1.74742 3.33565L3.66668 5.49481L8.2526 0.335647C8.61952 -0.0771359 9.25159 -0.114317 9.66437 0.252601Z"/></svg>')`,
			},
			'& + .MuiSwitch-track': {
				backgroundColor: '#17B67C',
				opacity: 1,
				border: 0,
			},
			'&.Mui-disabled + .MuiSwitch-track': {
				opacity: 0.5,
			},
		},

		'&.Mui-focusVisible .MuiSwitch-thumb': {
			color: '#17B67C',
			border: '6px solid #fff',
		},

		'&.Mui-disabled .MuiSwitch-thumb': {
			color:theme.palette.grey[100],
		},
		'&.Mui-disabled + .MuiSwitch-track': {
			opacity: 0.7,
		},
	},
	'& .MuiSwitch-thumb': {
		boxSizing: 'border-box',
		width: 22,
		height: 22,
		'&::before': {
			content: "''",
			position: 'absolute',
			width: '100%',
			height: '100%',
			left: 0,
			top: 0,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="8" width="8" viewBox="0 0 8 8"><path fill="${encodeURIComponent(
				'#AAB8D1',
			)}" d="M0.234315 0.234315C0.546734 -0.0781049 1.05327 -0.0781049 1.36569 0.234315L4 2.86863L6.63432 0.234315C6.94673 -0.0781049 7.45327 -0.0781049 7.76569 0.234315C8.0781 0.546734 8.0781 1.05327 7.76569 1.36569L5.13137 4L7.76569 6.63432C8.0781 6.94673 8.0781 7.45327 7.76569 7.76569C7.45327 8.0781 6.94673 8.0781 6.63432 7.76569L4 5.13137L1.36569 7.76569C1.05327 8.0781 0.546734 8.0781 0.234315 7.76569C-0.0781049 7.45327 -0.0781049 6.94673 0.234315 6.63432L2.86863 4L0.234315 1.36569C-0.0781049 1.05327 -0.0781049 0.546734 0.234315 0.234315Z"/></svg>')`,
		},
	},
	'& .MuiSwitch-track': {
		borderRadius: 26 / 2,
		backgroundColor: '#D3DFF0',
		opacity: 1,
		transition: theme.transitions.create(['background-color'], {
			duration: 500,
		}),
	},
}));