import { createRef, memo, useEffect, useState } from 'react'
import styles from './InputOTP.module.scss'
import cl from 'classnames'

export const InputOTP = memo((props) => {
	const {size = 5, onChange, name, ...otherProps} = props

	const [otp, setOtp] = useState(Array.from({ length: size }, (v, i) => ""));
	const otpInputRefs = [...otp.map((v, i) => createRef())];

	useEffect(() => {
		otpInputRefs[0].current.focus()
	}, [])

	const handleChange = (index, e) => {
		let { value } = e.target;
		if (!isNaN(value)) {
			setOtp(prev => {
				let res = prev.map((item, i) => i === index ? value : item)
				onChange(res.join(""))

				return res
			})
		}
	}

	const handleOtpKeyDown = (index, e) => {
		let { value } = e.target;
		if (["Backspace", "Delete"].includes(e?.key)) {
			if (e.key === "Backspace" && index !== 0) {
				if (otp[index - 1] && otp[index + 1] && !!!value) {
					otpInputRefs[index].current.focus();
				}
				else {
					otpInputRefs[index - 1].current.focus();
				}
			} else {
				otpInputRefs[index].current.focus();
			}
		} else {
			if (value.toString().trim().length !== 0 && !isNaN(value) && otpInputRefs?.length !== index + 1) {
				otpInputRefs[index + 1].current.focus();
			}
		}
	}

	return (
		<div className={cl(styles.wrapper)}>
			{otp.map((v, i) => (
				<div key={i} className={cl(styles.item)}>
					<input
						type="number"
						name={`otp-${i}`}
						id={`otp-${i}`}
						onInput={(e) => (e.target.value) = e.target.value.slice(-1)}
						value={otp[i]}
						onKeyUp={(e) => handleOtpKeyDown(i, e)}
						onChange={(e) => handleChange(i, e)}
						ref={otpInputRefs[i]}
					/>
				</div>
			))}
		</div>
	)
})