import { Modal, ModalBackButton } from '@/components/Modal'
import styles from './Modal.module.scss'
import React, { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cl from 'classnames'
import { Field as FormikField, Form, Formik } from 'formik'
import { sickLeaveThunks } from '@/entity'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentLanguage } from '@/i18next'
import { Button, Checkbox, Field } from '@/ui'
import { LoadingBlocker, LoadingScreen } from '@/components/Loader'
import { message } from 'antd'
import { InputOTP } from '@/components/InputOTP/InputOTP'

const FORM_NAME = {
	employers: 'employers',
	isApprove: 'isApprove',
	confirmCode: 'confirmCode',
	listId: 'listId',
	phone: 'phone',
}
const formInitialState = {
	[FORM_NAME.employers]: [],
	[FORM_NAME.isApprove]: false,
	[FORM_NAME.confirmCode]: null,
	[FORM_NAME.listId]: null,
	[FORM_NAME.phone]: null,
}

const TYPES = {
	CONFIRM: 'CONFIRM',
	OTP: 'OTP',
}

const modalInitialState = {
	item: null,
	type: {
		[TYPES.CONFIRM]: false,
		[TYPES.OTP]: false,
	},
}

export const SendModal = memo(({
																 onClose,
																 item,
																 onBack,
															 }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const lang = getCurrentLanguage()

	const { user, actualContract, employeePhone, setSickLeave } = useSelector((state) => ({
		user: state?.user?.user,
		actualContract: state?.sickLeave?.actualContract,
		employeePhone: state?.sickLeave?.employeePhone,
		setSickLeave: state?.sickLeave?.setSickLeave,
	}))

	const [modalData, setModalData] = useState(modalInitialState)

	useEffect(() => {
		if (user) {
			let body = {
				iin: user?.iin,
			}

			if (!actualContract.data?.length) {
				dispatch(sickLeaveThunks.getActualContract(body))
			}
		}
	}, [])


	const handleFormSubmit = (data) => {
		const res = {
			...data,
			employers: data.employers?.map(employer => {
				let result = actualContract?.data?.find(contract => contract.key === employer)

				let currentLang = {
					rus: result?.companyName,
					kz: result?.companyNameKaz,
				}

				return {
					bin: result?.bin,
					orgName: currentLang[lang],
				}
			}),
		}

		let body = {
			data: user?.iin,
			onSuccess: (phone) => {
				setModalData(prevState => ({
					item: res,
					type: {
						...prevState.type,
						[TYPES.CONFIRM]: true,
					},
				}))
			},
		}

		dispatch(sickLeaveThunks.getEmployeePhone(body))
	}

	const handleCloseApproveSms = () => {
		setModalData(modalInitialState)
	}

	const handleAcceptToSendSms = () => {
		let body = {
			data: {
				...modalData.item,
				[FORM_NAME.listId]: item.listId,
				[FORM_NAME.phone]: employeePhone.data,
			},
			onSuccess: () => {
				setModalData(prevState => ({
					...prevState,
					type: {
						[TYPES.CONFIRM]: false,
						[TYPES.OTP]: true,
					},
				}))
			}
		}

		dispatch(sickLeaveThunks.sendSms(body))
	}

	const handleConfirmSms = (data) => {
		let body = {
			data: {
				...modalData.item,
				[FORM_NAME.confirmCode]: data.confirmCode,
				[FORM_NAME.listId]: item.listId,
				[FORM_NAME.phone]: employeePhone?.data,
			},
			onSuccess: () => {
				handleCloseApproveSms()
				onClose()
				message.success(t('success'))
			},
		}

		dispatch(sickLeaveThunks.setSickLeave(body))
	}


	return (
		<>
			<Modal windowClassName={cl(styles.modal_wrapper, styles.modal_wrapper__send)}>
				<ModalBackButton onClick={onBack} />
				<div className={`${styles.wrapper} scroll`}>
					<div className={styles.header}>
						<h1 className={cl(styles.title, styles.titlebig)}>{t('sickLeave.send_employer')}</h1>
					</div>

					<div className={cl(styles.header, 'mt-4')}>
					<span className={cl(styles.title, styles.titlesmall)}>
						{t('sickLeave.number', { number: item.total?.number })}
					</span>
						<span className={styles.subtitle}>{item.total?.sinceReleasWrk} - {item.total?.tillReleasWrk}</span>
					</div>

					{actualContract.loading ? <LoadingScreen /> :
						<div className={cl(styles.body, 'mt-4')}>
							<Formik initialValues={formInitialState} onSubmit={handleFormSubmit}>
								{({  values }) => {
									return (
										<Form>
											<FormikField name={FORM_NAME.employers}>
												{({ field }) => (
													<Field
														fieldType="multipleSelect"
														options={actualContract?.data?.map(contract => ({
															code: contract.key,
															kz: `${contract.companyNameKaz}, ${contract.establishedPost}`,
															rus: `${contract.companyName}, ${contract.establishedPost}`,
														})) || []}
														label={t('employer')}
														placeholder={t('sickLeave.choose_employer')}
														{...field}
													/>
												)}
											</FormikField>

											<div className={cl('mt-4')}>
												<FormikField name={FORM_NAME.isApprove}>
													{({ field }) => (
														<Checkbox
															{...field}
															label={t('sickLeave.approve_personal_data')}
														/>
													)}
												</FormikField>
											</div>

											<div className={cl(styles.footer, 'mt-4')}>
												<Button type="bare" onClick={onClose}>
													{t('cancel')}
												</Button>

												<Button type="submit" disabled={!(values.isApprove && values.employers)}>
													{t('send')}
												</Button>
											</div>
										</Form>
									)
								}}
							</Formik>
						</div>
					}
				</div>
			</Modal>

			{
				modalData.type[TYPES.CONFIRM] &&
				<Modal windowClassName={cl(styles.modal_wrapper, styles.modal_wrapper__phone)}>
					<div className={styles.sms_text}>
						{t('tel_bmg')}
						<div className={styles.tel}>{employeePhone?.data}</div>
						{t('send_sms')}

						<div className={cl(styles.footer, 'mt-4')}>
							<Button type="bare" onClick={handleCloseApproveSms}>
								{t('cancel')}
							</Button>

							<Button onClick={handleAcceptToSendSms}>
								{t('send')}
							</Button>
						</div>
					</div>
				</Modal>
			}

			{
				modalData.type[TYPES.OTP] &&
				<Modal windowClassName={cl(styles.modal_wrapper, styles.modal_wrapper__sms)}>
					<Formik initialValues={formInitialState} onSubmit={handleConfirmSms}>
						{({values}) => {
							return (
								<Form>
									<span className={cl(styles.title, styles.titlesmall)}>
										{t('code_bmg_placeholder')}
									</span>
									<div className={cl('mx-auto', 'mt-4')}>
										<FormikField name={FORM_NAME.confirmCode}>
											{({ field }) => (
												<InputOTP size={6} {...field} />
											)}
										</FormikField>
									</div>

									<div className={cl(styles.footer, 'mt-4')}>
										<Button type="bare" onClick={handleCloseApproveSms}>
											{t('cancel')}
										</Button>

										<Button type='submit' disabled={!(values?.confirmCode?.length === 6)}>
											{t('send')}
										</Button>
									</div>
								</Form>
							)
						}}
					</Formik>
				</Modal>
			}

			{(employeePhone.loading || setSickLeave.loading) && <LoadingBlocker />}
		</>
	)
})