import * as Yup from 'yup'

const objectStructure = {
	dept: Yup.object().shape({
		nameKz: Yup.string().required({ key: 'required' }),
		nameRu: Yup.string().required({ key: 'required' }),
	}),
	deptTypeCode: Yup.string().when('subsidiary', {
		is: false,
		then: Yup.string().required({ key: 'required' }).nullable(),
	}),
	ddistrictCode: Yup.string()
		.when('deptTypeCode', {
			is: (deptTypeCode) => deptTypeCode === '23',
			then: Yup.string().required({ key: 'required' }).nullable(),
			otherwise: Yup.string().notRequired().nullable(),
		})
		.nullable(),

	dregionCode: Yup.string()
		.when('deptTypeCode', {
			is: (deptTypeCode) => deptTypeCode === '23',
			then: Yup.string().required({ key: 'required' }).nullable(),
			otherwise: Yup.string().notRequired().nullable(),
		})
		.nullable(),
}

export const ValidationStructSchema = Yup.object().shape({
	units: Yup.array().of(Yup.object().shape(objectStructure)),
})
