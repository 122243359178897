import { Modal, ModalBackButton } from '@/components/Modal'
import styles from './Modal.module.scss'
import React, { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cl from 'classnames'
import { Timeline } from 'antd'
import Button from '@/components/Button'
import { Icon } from '@/components/Icon'
import { View, Upload } from '@/app/icons'
import { useSelector } from 'react-redux'
import { userSelectors } from '@/entity'

const CustomDot = ({ color }) => {
	return <div className={cl(styles.timelineDot)} style={{ background: color }} />
}

let color = {
	'1': '#17B67C',
	'2': '#eba834',
	'3': '#ff0000',
	'4': '#111',
}

export const ViewModal = memo(({
																 onClose,
																 item,
																 onUpload
															 }) => {
	const { t } = useTranslation()

	const { role, sickLeave } = useSelector((state) => ({
		role: userSelectors.activeRole(state),
	}))

	const currentStatus = {
		'1': t('sickLeave.open'),
		'2': t('sickLeave.continue'),
		'3': t('sickLeave.close'),
		'4': t('sickLeave.deleted'),
	}

	const information = useMemo(() => (
		[
			{
				label: t('issue_date'),
				desc: item.total?.sinceReleasWrk,
			},
			{
				label: t('sickLeave.date_end_work'),
				desc: `${(item.total?.sinceReleasWrk && item.total?.tillReleasWrk) ? `${item.total?.sinceReleasWrk}-${item.total?.tillReleasWrk}` : ``}`,
			},
			{
				label: t('sickLeave.start_date_work'),
				desc: item.total?.startWrk,
			},
			{
				label: t('sickLeave.diagnosis'),
				desc: item.total?.conclMKB10 || item.total?.mkb10,
			},
			{
				label: t('sickLeave.type_temporary_disability'),
				desc: item.total?.spcifyTempDis,
			},
			{
				label: t('sickLeave.mode_type'),
				desc: item.total?.typeRegime,
			}
		]
	), [t])

	const infoTitle = {
		ROLE_EMPLOYEE: t('sickLeave.information'),
		ROLE_EMPLOYER: item.fio,
	}

	return (
		<Modal windowClassName={cl(styles.modal_wrapper, styles.modal_wrapper__send)}>
			<ModalBackButton onClick={onClose} />

			<div className={cl(styles.wrapper, 'scroll')}>
				{
					item.total?.number &&
					<div className={cl(styles.header, 'mb-4')}>
						<h1 className={cl(styles.title, styles.titlebig)}>{t('sickLeave.number', { number: item.total?.number })}</h1>
						<span className={styles.subtitle}>{item.status}</span>
					</div>
				}

				{
					role === 'ROLE_EMPLOYEE' &&
					<div className={cl('mb-4')}>
						<Button onClick={onUpload}>
							<Icon component={Upload} className={cl('me-2')} />
							{t('actions.sendEmployer')}
						</Button>
					</div>
				}


				<div className={cl(styles.body)}>
					<span className={cl(styles.med_title)}>{item.nameOfMo}</span>

					<div>
						<Timeline className={cl('mt-5', styles.timeline)}>
							{item.chunck?.map((chunck, chunckIdx) => {
								let date = item.chunck?.find(innerChunck => innerChunck.dateOfIssue)
								return (
									<Timeline.Item
										key={chunckIdx}
										dot={<CustomDot color={color[chunck.status]} />}
									>
										<span className={styles.timeline__date}>{date?.dateOfIssue}</span> {' '}
										<span className={styles.timeline__status}>{currentStatus[chunck.status]}</span>
									</Timeline.Item>
								)
							})}
						</Timeline>
					</div>

					<div>
						<span className={cl(styles.med_title)}>
							{infoTitle[role]}
						</span>

						<div className={cl(styles.info, 'mt-4')}>
							{information.map((info, infoIdx) => (
								<div key={infoIdx} className={cl(styles.info__item)}>
									<span className={cl(styles.info__title)}>{info.label}</span>
									<span className={cl(styles.info__subtitle)}>{info.desc}</span>
								</div>
							))}
						</div>
					</div>

					<div className={cl('mt-4', styles.footer)}>
						<Button  onClick={onClose}>
							{t('close')}
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	)
})