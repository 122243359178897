import { Logo as LogoImage } from '@/app/images'
import user from '../../../assets/home/User.svg'
import { Link } from 'react-router-dom'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import LangFooter from '@/components/LangFooter'
import MenuItem from '@/widgets/sidebar/MenuItem'
import useEnbekEcoSystemLink from '@/shared/lib/hooks/useEnbekEcoSystemLink'
import { getCurrentLanguage } from '@/i18next'

const Sidebar = ({ sidebar, setSidebar }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()

	const { refEnbek, refSkills, refBusiness, refCareer, refMigration, refMansap } = useEnbekEcoSystemLink(lang)

	const closeSidebar = () => {
		setSidebar(false)
	}

	useEffect(() => {
		if (sidebar) document.body.classList.add('modal-opened')
		else document.body.classList.remove('modal-opened')
	}, [sidebar])

	const listItems = [
		{ link: '/about-project', title: t('about_project') },
		{ link: '/for-employers', title: t('for_employer') },
		{ link: '/for-workers', title: t('for_worker') },
		{ link: 'reference-information/1', title: t('reference_information') },
		{ link: '', title: 'Enbek', external: true, href: refEnbek },
		{ link: '', title: 'Enbek Skills', external: true, href: refSkills },
		{ link: '', title: 'Enbek Business', external: true, href: refBusiness },
		{ link: '', title: 'Enbek Career', external: true, href: refCareer },
		{ link: '', title: 'Enbek Migration', external: true, href: refMigration },
		{ link: '', title: 'Enbek Mansap Kompasy', external: true, href: refMansap },
	]

	return (
		<div className={!sidebar ? 'sidebar-home' : 'sidebar-home active'}>
			<div className="container-mobile ">
				<div className="sidebar-head">
					<div className="sidebar-head__wrapper">
						<div onClick={closeSidebar} className="gamburger active">
							<span />
							<span />
							<span />
						</div>
						<Link to={'/'}>
							<img src={LogoImage} alt="enbek" />
						</Link>
						<Link to="/register">
							<img src={user} />
						</Link>
					</div>
					<ul className="menu">
						{listItems.map((item, index) => (
							<MenuItem key={index} {...item} />
						))}
						<li>
							<LangFooter />
						</li>
					</ul>
				</div>
			</div>
		</div>
	)
}

export default Sidebar
