import * as types from './types'

export const actions = {
	getSickDataAction: (payload) => ({
		type: types.GET_SICK_DATA,
	}),

	getSickDataActionDone: (payload) => ({
		type: types.GET_SICK_DATA_DONE,
		payload,
	}),

	getSickDataActionFail: (payload) => ({
		type: types.GET_SICK_DATA_FAIL,
	}),


	getEmployeePhoneAction: (payload) => ({
		type: types.GET_EMPLOYEE_PHONE,
	}),

	getEmployeePhoneActionDone: (payload) => ({
		type: types.GET_EMPLOYEE_PHONE_DONE,
		payload,
	}),

	getEmployeePhoneActionFail: (payload) => ({
		type: types.GET_EMPLOYEE_PHONE_FAIL,
	}),


	sendSmsAction: (payload) => ({
		type: types.SEND_SMS,
	}),

	sendSmsActionDone: (payload) => ({
		type: types.SEND_SMS_DONE,
		payload,
	}),

	sendSmsActionFail: (payload) => ({
		type: types.SEND_SMS_FAIL,
	}),


	setSickLeaveAction: (payload) => ({
		type: types.SET_SICK_LEAVE,
	}),

	setSickLeaveActionDone: (payload) => ({
		type: types.SET_SICK_LEAVE_DONE,
		payload,
	}),

	setSickLeaveActionFail: (payload) => ({
		type: types.SET_SICK_LEAVE_FAIL,
	}),


	getActualContractAction: (payload) => ({
		type: types.GET_ACTUAL_CONTRACT,
	}),

	getActualContractActionDone: (payload) => ({
		type: types.GET_ACTUAL_CONTRACT_DONE,
		payload,
	}),

	getActualContractActionFail: (payload) => ({
		type: types.GET_ACTUAL_CONTRACT_FAIL,
	}),
}
