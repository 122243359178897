export const formFieldNames = {
	code: 'code',
	nameKz: 'nameKz',
	nameRu: 'nameRu',
	multiCreate: 'multiCreate',
	staffUnitsCount: 'staffUnitsCount',
	grossSalary: 'grossSalary',
	salarySupplement: 'salarySupplement',
	factSalary: 'factSalary',
	freelance: 'freelance',
	isDicPosition: 'isDicPosition',
	positionCode: 'positionCode',
	staffCategory: 'staffCategory',
}
