import React from 'react'

export const View = () => (
	<svg width="12" height="12" viewBox="0 0 12 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M11.9237 5.76655C11.8165 5.61991 9.26224 2.17578 5.99994 2.17578C2.73765 2.17578 0.183235 5.61991 0.0761486 5.76641C-0.0253829 5.90554 -0.0253829 6.09423 0.0761486 6.23336C0.183235 6.38001 2.73765 9.82413 5.99994 9.82413C9.26224 9.82413 11.8165 6.37999 11.9237 6.23348C12.0254 6.09438 12.0254 5.90554 11.9237 5.76655ZM5.99994 9.03293C3.59691 9.03293 1.51564 6.74699 0.899533 5.99969C1.51484 5.25173 3.59176 2.96699 5.99994 2.96699C8.40285 2.96699 10.484 5.25252 11.1004 6.00023C10.485 6.74817 8.40813 9.03293 5.99994 9.03293Z" />
		<path
			d="M6.00058 3.62598C4.69179 3.62598 3.62695 4.69081 3.62695 5.99961C3.62695 7.30841 4.69179 8.37325 6.00058 8.37325C7.30938 8.37325 8.37421 7.30841 8.37421 5.99961C8.37421 4.69081 7.30938 3.62598 6.00058 3.62598ZM6.00058 7.58202C5.12801 7.58202 4.41818 6.87217 4.41818 5.99961C4.41818 5.12706 5.12803 4.4172 6.00058 4.4172C6.87314 4.4172 7.58299 5.12706 7.58299 5.99961C7.58299 6.87217 6.87316 7.58202 6.00058 7.58202Z" />
	</svg>

)
