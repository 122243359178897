import { useCallback, useEffect, useState } from 'react'
import { ProfileTemplate } from '@/widgets'

import { contractsActions, userSelectors } from '@/entity'
import { RedirectUnAuthUser } from '@/processes'
import { useDispatch, useSelector } from 'react-redux'

import * as React from 'react'
import { styled } from '@mui/system'
import TabsUnstyled from '@mui/base/Tabs'
import TabPanelUnstyled from '@mui/base/TabPanel'
import { useTranslation } from 'react-i18next'
import { EmploymentContracts } from '@features/contracts/employmentContracts'
import { HrPagination } from '@/components/Pagination/hrPagination'
import { contractsSelectors, contractsThunks } from '@/entity/contracts'
import { TransferRequests } from '@features/contracts/transferRequests'
import { ElectronicContractsRequests } from '@features/contracts/electronicContractsRequests'
import Tab from '@/ui/atoms/tab'
import TabsList from '@/ui/atoms/tabsList'
import { employerProfileSelectors } from '@features/employerProfile/model'
import { CorrectionRequests } from '@features/contracts/correctionRequests'
import PreHeader from '@/components/home/components/PreHeader'
import { CollectiveContracts } from '@/features'
import { administrationThunks } from '@/entity/administration'

const TabPanel = styled(TabPanelUnstyled)({
	width: '100%',
	maxWidth: '90vw',
	margin: '32px 34px 0 0',
	fontFamily: 'Inter, sans-serif',
	fontSize: '0.875rem',
})

const Tabs = styled(TabsUnstyled)({})

const initialQueryState = {
	page: 0,
	pageSize: 10,
	rowsPerPage: [
		{ size: 10, label: '10' },
		{ size: 30, label: '30' },
		{ size: 50, label: '50' },
		{ size: 100, label: '100' },
	],
}

const initialFilterState = {
	contractNumber: '',
	companyName: '',
	employeeName: '',
	establishedPost: '',
	registerDate: '',
	dateFrom: '',
	dateTo: '',
	dcontractStatusCode: null,
	dcontractStateCode: null,
	dcontractTypeCode: null,
	srcText: '',
	sortDirection: 'desc',
	sortField: 'entryDate',
	terminationDate: null,
	dsendStatusCode: null,
}

export const EmploymentContractsPage = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const activeCompany = useSelector(employerProfileSelectors.activeCompany)
	const user = useSelector(userSelectors.user)
	const [activeTab, setActiveTab] = useState(0)
	const [queryParams, setQueryParams] = useState(initialQueryState)
	const [filters, setFilters] = useState(initialFilterState)
	const isMobile = window.innerWidth < 768
	const activeRole = useSelector(userSelectors.activeRole)
	const contractsData = useSelector(contractsSelectors.contractData)

	const handleChangeTab = (e, newValue) => setActiveTab(newValue)

	const handleChangePage = (e, page) => setQueryParams({ ...queryParams, page: page - 1 })

	const handleChangeRowsPerPage = (size) => setQueryParams((prev) => ({ ...prev, page: 0, pageSize: size }))

	const fetchData = useCallback(
		(_filters = filters, _queryParams = queryParams) => {
			const { bin, iin } = _filters
			if ((!bin && !iin) || !activeRole) return
			const { page, pageSize } = _queryParams
			dispatch(
				contractsThunks.getContracts(
					{
						..._filters,
						bin: activeRole === 'ROLE_EMPLOYER' ? _filters.bin : null,
						iin: activeRole === 'ROLE_EMPLOYEE' ? _filters.iin : null,
					},
					page,
					pageSize
				)
			)
		},
		[queryParams, filters, activeRole]
	)

	const resetFilter = useCallback(() => {
		dispatch(contractsActions.setSelectedContracts([]))
		setFilters((prev) => ({
			bin: prev.bin,
			iin: prev.iin,
			...initialFilterState,
		}))
		setQueryParams(initialQueryState)
		fetchData(
			{
				bin: filters.bin,
				iin: filters.iin,
				...initialFilterState,
			},
			initialQueryState
		)
	}, [fetchData, initialFilterState])

	useEffect(() => fetchData(), [queryParams.page, queryParams.pageSize, filters.bin, filters.iin, activeRole])
	useEffect(
		() =>
			setFilters((state) => ({
				...state,
				...(activeRole === 'ROLE_EMPLOYER' ? { bin: activeCompany?.bin, iin: null } : { iin: user?.iin }),
			})),
		[activeRole, activeCompany, user]
	)
	const changeTabs = (tab) => {
		setActiveTab(tab)
	}
	return (
		<>
			{/*раскомментировать в случае траура*/}
			{/*<PreHeader />*/}
			<RedirectUnAuthUser>
				<ProfileTemplate title={'employment_contract'}>
					{/* Вкладки договоров */}
					<Tabs defaultValue={0} value={activeTab} onChange={handleChangeTab}>
						<TabsList>
							<Tab value={0}>{t('employment_contracts')}</Tab>
							{activeRole !== 'ROLE_EMPLOYEE' && (
								<div className={'flex'}>
									{!activeCompany.flEmployer ? (
										<>
											<Tab value={1}>{t('worker_transfer_requests')}</Tab>
											<Tab value={2}>{t('correction_requests_tab')}</Tab>
										</>
									) : null}
								</div>
							)}
							<Tab value={3}>{t('term_etd_requests')}</Tab>
							{activeRole !== 'ROLE_EMPLOYEE' && activeCompany?.collectiveContract ? (
								<Tab value={4}>{t('collective_contract')}</Tab>
							) : null}
						</TabsList>
						{/* Обычный трудовой договор */}
						<TabPanel value={0}>
							<EmploymentContracts
								filters={filters}
								setFilters={setFilters}
								fetchData={fetchData}
								resetFilter={resetFilter}
								page={queryParams.page}
								pageSize={queryParams.pageSize}
								setActiveTab={changeTabs}
							/>

							{/* пагинация */}
							<HrPagination
								id="hr-pagination"
								rowsPerPageitems={
									isMobile ? queryParams.rowsPerPage.filter((value) => value.size !== 100) : queryParams.rowsPerPage
								}
								rowsPerPage={queryParams.pageSize}
								numberOfElements={contractsData.numberOfElements}
								totalElements={contractsData.totalElements}
								totalPages={contractsData.totalPages}
								handleChangePage={handleChangePage}
								handleChangeRowsPerPage={handleChangeRowsPerPage}
								page={queryParams.page}
							/>
						</TabPanel>
						{activeRole !== 'ROLE_EMPLOYEE' ? (
							<TabPanel value={1}>
								<TransferRequests />
							</TabPanel>
						) : null}
						{/* Запросы на изменения ЭТК */}
						{activeRole !== 'ROLE_EMPLOYEE' ? (
							<TabPanel value={2}>
								<CorrectionRequests />
							</TabPanel>
						) : null}
						<TabPanel value={3}>
							<ElectronicContractsRequests setActiveTab={changeTabs} />
						</TabPanel>
						{activeRole !== 'ROLE_EMPLOYEE' && activeCompany?.collectiveContract ? (
							<TabPanel value={4}>
								<CollectiveContracts activeTab={activeTab} isMobile={isMobile}></CollectiveContracts>
							</TabPanel>
						) : null}
					</Tabs>
				</ProfileTemplate>
			</RedirectUnAuthUser>
		</>
	)
}
