export const GET_BRANCHES = 'administration/GET_BRANCHES'
export const GET_BRANCHES_DONE = 'administration/GET_BRANCHES_DONE'
export const GET_BRANCHES_FAIL = 'administration/GET_BRANCHES_FAIL'

export const SET_SIGN = 'administration/SET_SIGN'
export const SET_SIGN_DONE = 'administration/SET_SIGN_DONE'
export const SET_SIGN_FAIL = 'administration/SET_SIGN_FAIL'

export const SET_GRANTS = 'administration/SET_GRANTS'
export const SET_GRANTS_DONE = 'administration/SET_GRANTS_DONE'
export const SET_GRANTS_FAIL = 'administration/SET_GRANTS_FAIL'

export const SEND_SIGNED_XML = 'administration/SEND_SIGNED_XML'
export const SEND_SIGNED_XML_DONE = 'administration/SEND_SIGNED_XML_DONE'
export const SEND_SIGNED_XML_FAIL = 'administration/SEND_SIGNED_XML_FAIL'

export const GET_STAFFING_ADMINISTRATION_ORGS = 'administration/GET_STAFFING_ADMINISTRATION_ORGS'
export const GET_STAFFING_ADMINISTRATION_ORGS_DONE = 'administration/GET_STAFFING_ADMINISTRATION_ORGS_DONE'
export const GET_STAFFING_ADMINISTRATION_ORGS_FAIL = 'administration/GET_STAFFING_ADMINISTRATION_ORGS_FAIL'

export const SET_STAFFING_ADMINISTRATION_ORG = 'administration/SET_STAFFING_ADMINISTRATION_ORG'
export const SET_STAFFING_ADMINISTRATION_ORG_DONE = 'administration/SET_STAFFING_ADMINISTRATION_ORG_DONE'
export const SET_STAFFING_ADMINISTRATION_ORG_FAIL = 'administration/SET_STAFFING_ADMINISTRATION_ORG_FAIL'

export const GET_STAFFING_GRANTS = 'administration/GET_STAFFING_GRANTS'
export const GET_STAFFING_GRANTS_DONE = 'administration/GET_STAFFING_GRANTS_DONE'
export const GET_STAFFING_GRANTS_FAIL = 'administration/GET_STAFFING_GRANTS_FAIL'