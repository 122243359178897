import { request } from '@/shared/api/request'

export const getActualXml = ({params, payload: {orgId}}) => {
	let currentParams = {
		orgId: orgId,
		...params
	}

	return request({ url: `staff/xml/actual/get`, method: 'GET', params: currentParams })
}
