import { Modal } from '@/components/Modal'
import React, { memo, useEffect } from 'react'
import styles from './Modal.module.scss'
import { message, Rate } from 'antd'
import cl from 'classnames'
import { Field, Form, Formik } from 'formik'
import { Button, Textarea } from '@/ui'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { contractsThunks, eColContractsThunks, userSelectors } from '@/entity'
import { contractActionsSelectors } from '@features/contracts/model'
import { reviewActions, reviewSelectors, reviewThunks } from '@/entity/review'
import { useDigitalSign } from '@/shared'
import { ReviewInfoWarning } from '@/features'

/**
 * @param {review | answer} modalViewType
 * @param {add | edit} formActionType
 */

export const ReviewEditModal = memo(({
																			 formActionType,
																			 modalViewType,
																			 onClose,
																			 onSubmit,
																			 onSuccess,
																			 item,
																		 }) => {

	const {
		t,
		i18n: { language },
	} = useTranslation()

	const dispatch = useDispatch()

	const { successPayload, onSign, onClearPayload } = useDigitalSign()

	const { role, contract, currentReview } =
		useSelector((state) => ({
			role: userSelectors.activeRole(state),
			contract: contractActionsSelectors.contractView()(state),
			currentReview: state.review.currentReview,
		}))

	const currentType = {
		review: currentReview?.review,
		answer: currentReview?.answer,
	}

	const formInitialValues = {
		reviewText: currentType?.[modalViewType]?.text,
		...(modalViewType === 'review' ? {
			rating: currentReview?.rating,
		} : {}),
	}

	const reviewType = {
		review: {
			ROLE_EMPLOYEE: 'employeeReview',
			ROLE_EMPLOYER: 'employerReview',
		},
		answer: {
			ROLE_EMPLOYEE: 'employeeAnswer',
			ROLE_EMPLOYER: 'employerAnswer',
		}
	}

	useEffect(() => {
		if(successPayload){
			let body = {
				data: {
					externalId: item?.externalId,
					signedXml: successPayload
				},
				onSuccess: () => {
					handleClose()
					message.success(t('reviews.success_payload'), 5)
					onSuccess(item)
				}
			}

			dispatch(reviewThunks.setReview(body))
		}
	}, [successPayload])

	const handleSubmit = (values) => {
		let body = {
			key: {
				modalViewType: modalViewType,
				formActionType: formActionType,
				item: item
			},
			values: {
				rating: values.rating,
				reviewText: values.reviewText,
				contractExternalId: item.contractExternalId,
				iin: item.iin,
				bin: item.bin,
				reviewerType: reviewType?.[modalViewType]?.[role],
			}
		}

		let type = {
			employeeReview: 'review',
			employerReview: 'review',
			employeeAnswer: 'answer',
			employerAnswer: 'answer',
		}

		if(values.reviewText || values.rating){
			onSubmit(
				body,
				async (xml) => {
					onSign(xml)

					if(currentReview){
						dispatch(reviewActions.currentReview({
							...currentReview,
							rating: body.values.rating,
							[type[body.values.reviewerType]]: {
								...currentReview[type[body.values.reviewerType]],
								text: body.values.reviewText
							}
						}))
					}
				},
			)
		}
	}

	const handleClose = () => {
		onClose()
		dispatch(reviewActions.currentReview(null))
		onClearPayload()
	}

	const title = {
		add: {
			review: t('reviews.set_review'),
			answer: t('reviews.set_answer'),
		},
		edit: {
			review: t('reviews.edit_review'),
			answer: t('reviews.edit_answer'),
		},
	}

	const subtitle = {
		add: {
			review: {
				ROLE_EMPLOYEE: t('reviews.set_review_employer_agreement'),
				ROLE_EMPLOYER: t('reviews.set_review_employee_agreement'),
			},
			answer: {
				ROLE_EMPLOYEE: t('reviews.set_answer_agreement'),
				ROLE_EMPLOYER: t('reviews.set_answer_agreement'),
			},
		},
		edit: {
			review: {
				ROLE_EMPLOYEE: t('reviews.edit_review_employee_agreement'),
				ROLE_EMPLOYER: t('reviews.edit_review_employer_agreement'),
			},
			answer: {
				ROLE_EMPLOYEE: t('reviews.edit_answer_employee_agreement'),
				ROLE_EMPLOYER: t('reviews.edit_answer_employer_agreement'),
			},
		},
	}

	const ratingValue = {
		1: t('reviews.star1'),
		2: t('reviews.star2'),
		3: t('reviews.star3'),
		4: t('reviews.star4'),
		5: t('reviews.star5'),
	}

	const reviewAlertType = {
		ROLE_EMPLOYEE: t('reviews.view_alert_text_employer'),
		ROLE_EMPLOYER: t('reviews.view_alert_text_employee'),
	}

	return (
		<Modal windowClassName={cl(styles.modal_wrapper, {
			[styles.modal_wrapper__answer]: modalViewType === 'answer',
			[styles.modal_wrapper__review]: modalViewType === 'review',
		})} onClose={handleClose}>
			<p className={styles.title}>
				{title[formActionType][modalViewType]}
			</p>

			<p className={styles.subtitle}>
				{subtitle[formActionType][modalViewType][role]}
			</p>

			<Formik
				initialValues={formInitialValues}
				onSubmit={handleSubmit}
			>
				{({ isValid, values, setFieldValue }) => {
					return (
						<Form>
							{
								modalViewType === 'review' &&
								<Field name="rating">
									{({ field }) => (
										<div className={cl(styles.rating, 'mb-2')}>
											<p className={cl(styles.rating__text, 'mb-0')}>
												{t('reviews.employee_rating')}
											</p>
											<Rate
												onChange={(value) => setFieldValue(field.name, value)}
												value={field.value}
												className={styles.rating__component}
											/>

											<span className={cl('mb-3', 'mt-1')}>{ratingValue[field.value] || ''}</span>
										</div>
									)}
								</Field>
							}


							<Field name="reviewText">
							{({ field }) => (
									<Textarea  {...field} placeholder={t('reviews.placeholder_text')} />
								)}
							</Field>

							{
								modalViewType === 'answer' &&

								<div className={'my-4'}>
									<ReviewInfoWarning title={t('attention')} text={reviewAlertType[role]} />
								</div>
							}

							<div className={cl(styles.footer, 'mt-4')}>
								<Button type="submit">
									{t('sign_and_send')}
								</Button>

								<Button transparent onClick={handleClose}>
									{t('no_cancel')}
								</Button>
							</div>
						</Form>
					)
				}}
			</Formik>

		</Modal>
	)
})