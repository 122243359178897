import { RedirectUnAuthUser } from '@/processes'
import { ProfileTemplate } from '@/widgets'
import { useTranslation } from 'react-i18next'
import SocialPaymentMainTab from '@pages/PersonalAffairs/Tabs/SocialPaymentMainTab/SocialPaymentMainTab'
import * as React from 'react'

export const Payment = () => {
	const { t } = useTranslation()

	return (
		<RedirectUnAuthUser>
			<ProfileTemplate title={'payment.title'}>
				<SocialPaymentMainTab />
			</ProfileTemplate>
		</RedirectUnAuthUser>
	)
}