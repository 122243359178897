import { request } from '@/shared/api/request'

export const createProject = ({data, params}) => {
	return request({
		method: 'POST',
		url: 'staff/createProject',
		data,
		params
	})
}
