import { request } from '@/shared/api/request'

export const addStructure = ({data, params}) => {
	return request({
		method: 'POST',
		url: 'staff/structure/add',
		data: data,
		params: params
	})
}
