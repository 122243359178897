import * as types from './types'

const data = {
	loading: false,
	success: false,
	failed: false,
	data: null,
}

const initialState = {
	sickLeave: data,
	actualContract: data,
	employeePhone: data,
	setSickLeave: data,
}

export const reducer = (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case types.GET_SICK_DATA:
			return { ...state, sickLeave: { ...data, loading: true } }

		case types.GET_SICK_DATA_DONE:
			return { ...state, sickLeave: { ...data, data: payload, success: true } }

		case types.GET_SICK_DATA_FAIL:
			return { ...state, sickLeave: { ...data, failed: true } }


		case types.GET_ACTUAL_CONTRACT:
			return { ...state, actualContract: { ...data, loading: true } }

		case types.GET_ACTUAL_CONTRACT_DONE:
			return { ...state, actualContract: { ...data, data: payload, success: true } }

		case types.GET_ACTUAL_CONTRACT_FAIL:
			return { ...state, actualContract: { ...data, failed: true } }


		case types.SET_SICK_LEAVE:
			return { ...state, setSickLeave: { ...data, loading: true } }

		case types.SET_SICK_LEAVE_DONE:
			return { ...state, setSickLeave: { ...data, data: payload, success: true } }

		case types.SET_SICK_LEAVE_FAIL:
			return { ...state, setSickLeave: { ...data, failed: true } }


		case types.GET_EMPLOYEE_PHONE:
			return { ...state, employeePhone: { ...data, loading: true } }

		case types.GET_EMPLOYEE_PHONE_DONE:
			return { ...state, employeePhone: { ...data, data: payload, success: true } }

		case types.GET_EMPLOYEE_PHONE_FAIL:
			return { ...state, employeePhone: { ...data, failed: true } }

		default:
			return state
	}
}
