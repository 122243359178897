import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './PositionForm.module.scss'
import { Field as FormikField, Form as FormikForm, useFormikContext } from 'formik'
import { Button, Field } from '@/ui'
import { formFieldNames } from './formFieldNames'
import { WrappedCheckbox } from '@features/contracts/registrationEmployerContract/form/fields'
import { formatNumber } from '@/utils'
import RadioStaff from '@/components/RadioStaff/RadioStaff'
import { useSelector } from 'react-redux'
import { contractActionsSelectors } from '@features/contracts/model'

export const Form = ({ onClose, isEdit, activeCompany }) => {
	const { t } = useTranslation()
	const { setFieldValue, values } = useFormikContext()
	const { positions, positionsByKnz, establishedPostPositionCategoryRelation, laborMobilityCenterCategory } =
		useSelector(contractActionsSelectors.options)
	const lmc = activeCompany?.lmc
	const [filteredPositionsByKnz, setFilteredPositionsByKnz] = useState(positionsByKnz)
	const [filteredLaborMobilityCenterCategory, setFilteredLaborMobilityCenterCategory] =
		useState(laborMobilityCenterCategory)
	const [isLmcDisabled, setIsLmcDisabled] = useState(true)

	useEffect(() => {
		if (!lmc) {
			setIsLmcDisabled(false)
		}
	}, [])

	useEffect(() => {
		if (filteredPositionsByKnz.length === 1) {
			setFieldValue('positionCode', filteredPositionsByKnz[0]?.code)
		}
		if (filteredLaborMobilityCenterCategory.length === 1) {
			setFieldValue('staffCategory', filteredLaborMobilityCenterCategory[0]?.code)
		}
	}, [filteredPositionsByKnz, filteredLaborMobilityCenterCategory, setFieldValue])

	const [inputValues, setInputValues] = useState({
		grossSalary: '',
		salarySupplement: '',
	})

	const handleInputChange = (fieldName) => (event) => {
		let value = event.target.value
		if (value && !inputValues[fieldName]) {
			setInputValues({ ...inputValues, [fieldName]: value })
		} else {
			const onlyNumbers = value.replace(/\D/g, '')
			const numberWithSpaces = formatNumber(onlyNumbers)
			setInputValues({ ...inputValues, [fieldName]: numberWithSpaces })
			setFieldValue(fieldName, onlyNumbers)
		}
	}

	const onPositionChange = (code) => {
		setIsLmcDisabled(false)
		if (lmc) {
			setFieldValue('positionCode', null)
			setFieldValue('staffCategory', null)

			if (!code) {
				setFieldValue('position', null)
				return
			}
			setFieldValue('position', code)
			const eppcr = establishedPostPositionCategoryRelation.filter((rel) => rel.establishedPostCode === code)
			setFilteredPositionsByKnz(positionsByKnz.filter((item) => eppcr.some((x) => x.positionCode === item.code)))
			setFilteredLaborMobilityCenterCategory(
				laborMobilityCenterCategory.filter((item) => eppcr.some((x) => x.laborMobilityCenterCategoryCode === item.code))
			)
		} else {
			setFieldValue('position', code)
		}
	}

	return (
		<FormikForm className={styles.form}>
			<section>
				<div className={styles.item}>
					<div className={`${styles.fields} ${styles.fields_verticalCenter}`}>
						<div className={styles.label}>
							<FormikField name={formFieldNames.freelance}>
								{({ field }) => <WrappedCheckbox {...field} label={t('staff_modal.freelancer_position')} />}
							</FormikField>
						</div>
					</div>
					{!lmc && (
						<FormikField name={formFieldNames.isDicPosition}>
							{({ field }) => (
								<div className={'flex'}>
									<RadioStaff
										label={t('workplace.manual_input')}
										value="2"
										id="2"
										onChange={(e) => setFieldValue(field.name, e.target.value)}
										checked={field.value === '2'}
									/>
									<RadioStaff
										label={t('workplace.directory')}
										value="3"
										id="3"
										onChange={(e) => setFieldValue(field.name, e.target.value)}
										checked={field.value === '3'}
									/>
								</div>
							)}
						</FormikField>
					)}
					{values[formFieldNames.isDicPosition] === '2' ? (
						<div className={styles.twoFields}>
							<FormikField name={formFieldNames.nameKz}>
								{({ field, meta: { touched, error } }) => (
									<Field
										type="text"
										name={t('staff_modal.position_kz')}
										label={t('staff_modal.position_kz')}
										placeholder={t('staff_modal.position_placeholder')}
										isRequired
										error={touched && error}
										{...field}
									/>
								)}
							</FormikField>
							<FormikField name={formFieldNames.nameRu}>
								{({ field, meta: { touched, error } }) => (
									<Field
										type="text"
										label={t('staff_modal.position_ru')}
										placeholder={t('staff_modal.position_placeholder')}
										isRequired
										error={touched && error}
										{...field}
									/>
								)}
							</FormikField>
						</div>
					) : (
						<FormikField name="position">
							{({ field, meta: { touched, error } }) => (
								<Field
									fieldType="selectSearch"
									label={t('contract.info.position')}
									hint={t('hint_position')}
									placeholder={t('position_placeholder')}
									options={lmc ? positions?.filter((item) => item.lmc === true) : positions}
									error={touched && error}
									isRequired
									{...field}
									onChange={onPositionChange}
								/>
							)}
						</FormikField>
					)}
					<FormikField name={formFieldNames.positionCode}>
						{({ field, meta: { touched, error } }) => (
							<Field
								fieldType="selectSearch"
								label={t('contract.info.nkz_position')}
								hint={t('hint_position_knz')}
								placeholder={t('choose_from_directory')}
								options={lmc ? filteredPositionsByKnz : positionsByKnz}
								error={touched && error}
								isRequired
								disabled={
									(isEdit && isLmcDisabled) ||
									(!isEdit && lmc && filteredPositionsByKnz.length === 1) ||
									(!isEdit && lmc && !values.position?.length > 0)
								}
								{...field}
							/>
						)}
					</FormikField>
					{lmc && (
						<FormikField name={formFieldNames.staffCategory}>
							{({ field, meta: { touched, error } }) => (
								<Field
									fieldType="select"
									label={t('staff_modal.position_category')}
									placeholder={t('choose_from_directory')}
									options={lmc ? filteredLaborMobilityCenterCategory : laborMobilityCenterCategory}
									error={touched && error}
									isRequired
									disabled={
										(isEdit && isLmcDisabled) ||
										(!isEdit && lmc && filteredLaborMobilityCenterCategory.length === 1) ||
										(!isEdit && lmc && !values.positionCode?.length > 0)
									}
									{...field}
								/>
							)}
						</FormikField>
					)}
					<div className={`${styles.fields} ${styles.threeFields}`}>
						<div>
							{isEdit ? null : (
								<FormikField name={formFieldNames.multiCreate}>
									{({ field }) => (
										<div className={'flex'}>
											<RadioStaff
												label={t('workplace.count_tariff_rate')}
												value="0"
												id="0"
												onChange={(e) => setFieldValue(field.name, e.target.value)}
												checked={field.value === '0'}
											/>
											<RadioStaff
												label={t('workplace.tariff_rate_required')}
												value="1"
												id="1"
												onChange={(e) => setFieldValue(field.name, e.target.value)}
												checked={field.value === '1'}
											/>
										</div>
									)}
								</FormikField>
							)}
							<FormikField name={formFieldNames.staffUnitsCount}>
								{({ field, meta: { touched, error } }) => (
									<Field
										type="number"
										hideNumberArrows
										placeholder={t('staff.staff_units_placeholder')}
										error={touched && error}
										{...field}
										label={isEdit ? t('workplace.tariff_rate_required') : null}
										enablecheckfloat
									/>
								)}
							</FormikField>
						</div>
						<FormikField name={formFieldNames.grossSalary}>
							{({ field, meta: { touched, error } }) => (
								<Field
									type="text"
									hideNumberArrows
									fieldType={'inputNumber'}
									isRequired
									label={t('staffing_table.salary')}
									placeholder={t('staff_modal.salary_placeholder')}
									error={touched && error}
									{...field}
									value={inputValues.grossSalary || field.value}
									onChange={handleInputChange(formFieldNames.grossSalary)}
									autoComplete="off"
								/>
							)}
						</FormikField>
						<FormikField name={formFieldNames.salarySupplement}>
							{({ field, meta: { touched, error } }) => (
								<Field
									type="text"
									fieldType={'inputNumber'}
									hideNumberArrows
									label={t('staffing_table.supplement_surcharge')}
									placeholder={t('staff_modal.supplement_surcharge_placeholder')}
									error={touched && error}
									{...field}
									value={inputValues.salarySupplement || field.value}
									onChange={handleInputChange(formFieldNames.salarySupplement)}
									autoComplete="off"
								/>
							)}
						</FormikField>
					</div>
					{isEdit ? null : (
						<p>
							{t('workplace.count_tariff_rate')}
							<i>{' - ' + t('staff.staff_units_count_hint')}</i>
						</p>
					)}
				</div>
			</section>
			<div className={styles.actions}>
				<Button type="bare" onClick={onClose}>
					{t('cancel')}
				</Button>
				<Button textColor="green" borderColor="green" transparent border type="submit">
					{t('save')}
				</Button>
			</div>
		</FormikForm>
	)
}
