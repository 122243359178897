import { request } from '@/shared/api/request'

export const saveWorkPlace = ({ data, payload: {wpId}, params }) => {
	return request({
		method: 'POST',
		url: `staff/wplace/${wpId}/save`,
		data,
		params
	})
}
