import React, { memo, useCallback, useMemo } from 'react'
import styles from './AdministrationListItem.module.scss'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Clock, Signed, CrossRounded } from '@app/images'
import { Button } from '@/ui'
import cl from 'classnames'
import { Switch } from '@/components/Switch/Switch'
import { Field } from 'formik'
import { getCurrentLanguage } from '@/i18next'
import { isEqual } from 'lodash'

const KEY = {
	// contractRegistration: 'contractRegistration',
	stRegistration: 'stRegistration',
	stView: 'stView',
}

const FORM_ITEM = {
	status: 'status',
}

/**
 * @param {Object} props
 * @param {'switch' | 'button'} props.type
 * @returns {JSX.Element}
 *
 * @example
 * <AdministrationListItem type={'switch' || 'button'} />
 */

export const AdministrationListItem = memo(({ type, item, outerKey, setIsSignButtonVisible, handleSetRequest }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const lang = getCurrentLanguage()

	const { company } = useSelector((state) => ({
		company: state.company.company,
	}))

	const handleChangeValues = useCallback((form, grant, index, value) => {
		let currentValue = {
			true: {
				true: true,
				false: false
			},
			false: {
				true: true,
				false: false
			},
			null: {
				true: true,
				false: null
			},
		}

		let currentValueReg = {
			true: {
				//new
				true: {
					true: true,
					false: true,
					null: true
				},
				false: {
					true: true,
					false: false,
					null: false
				},
			},
			false: {
				true: {
					true: true,
					false: true,
					null: true
				},
				false: {
					true: true,
					false: false,
					null: false
				},
			},
			null: {
				true: {
					true: true,
					false: true,
					null: true
				},
				false: {
					true: true,
					false: false,
					null: false
				},
			},
		}

		let currentValueView = {
			true: {
				true: {
					true: true,
					false: false,
					null: false
				},
				false: {
					true: false,
					false: false,
					null: false
				},
			},
			false: {
				true: {
					true: true,
					false: false,
					null: true
				},
				false: {
					true: false,
					false: false,
					null: false
				},
			},
			null: {
				true: {
					true: true,
					false: true,
					null: null
				},
				false: {
					true: null,
					false: null,
					null: null
				},
			},
		}

		const data = {
			...form.values,
			array: form.values?.array?.map((fieldItem, fieldIdx) => {
				if (fieldIdx === outerKey) {
					let stViewIdx = fieldItem.grants.findIndex(i => i.grantCode === KEY.stView)
					let stRegIdx = fieldItem.grants.findIndex(i => i.grantCode === KEY.stRegistration)

					let stViewItem = fieldItem.grants.find(i => i.grantCode === KEY.stView)
					let stRegItem = fieldItem.grants.find(i => i.grantCode === KEY.stRegistration)

					return {
						...fieldItem,
						grants: fieldItem?.grants?.map((grantItem, grantIdx) => {
							let oldData = item?.grants?.[grantIdx]

							if (index === grantIdx) {
								return {
									...grantItem,
									status: currentValue[oldData?.status][value],
									isEdited: oldData?.status !== currentValue[oldData?.status][value]
								}
							} else if (grant.grantCode === KEY.stRegistration && stViewIdx === grantIdx) {
								return {
									...grantItem,
									status: currentValueReg[oldData?.status][value][stViewItem.status],
									isEdited: oldData?.status !== currentValueReg[oldData?.status][value][stViewItem.status],
								}
							} else if (grant.grantCode === KEY.stView && stRegIdx === grantIdx) {
								return {
									...grantItem,
									status: currentValueView[oldData?.status][value][stRegItem.status],
									isEdited: oldData?.status !== currentValueView[oldData?.status][value][stRegItem.status]
								}
							} else {
								return grantItem
							}
						}),
					}
				} else {
					return fieldItem
				}
			}),
		}

		const ifEditedData = {
			...data,
			array: data?.array?.map((fieldItem, fieldIdx) => {
				if (fieldIdx === outerKey) {
					let { isEdited, ...withoutIsEditedOuterObject } = fieldItem

					let filtered = {
						...withoutIsEditedOuterObject,
						grants: withoutIsEditedOuterObject?.grants?.map(({ isEdited, ...inner }) => inner)
					}

					return {
						...fieldItem,
						isEdited: !isEqual(filtered, item),
					}
				} else {
					return fieldItem
				}
			}),
		}

		const isSignButtonVisible = ifEditedData.array.some(i => i.isEdited)
		setIsSignButtonVisible(isSignButtonVisible)

		form.setValues(ifEditedData)
	}, [])

	const title = {
		[KEY.contractRegistration]: t('administration.view_register_emp_contracts'),
		[KEY.stRegistration]: t('administration.register_staffing_schedule'),
		[KEY.stView]: t('administration.view_staffing_schedule'),
	}

	const subtitle = {
		[KEY.stRegistration]: t('administration.requested_with_view_staffing_schedule'),
	}

	const content = useCallback((grant, index) => {
		let button = {
			null: (
				<div className={styles.status}>
					<img src={Clock} alt="status" className={styles.status__img} />
					<span className={cl(styles.status__text, styles.pending)}>{t('administration.request_send')}</span>
					<div className={styles.status__buttonContainer}>
						<Button
							buttonClassName={styles.status__button}
							textColor="green"
							borderColor="green"
							transparent border
							onClick={() => handleSetRequest(item, grant)}
						>
							{t('administration.revoke')}
						</Button>
					</div>
				</div>
			),
			true: (
				<div className={styles.status}>
					<img src={Signed} alt="status" className={styles.status__img} />
					<span className={cl(styles.status__text, styles.success)}>{t('administration.canAccess')}</span>
				</div>
			),
			false: (
				<div className={styles.status}>
					<img src={CrossRounded} alt="status" className={styles.status__img} />
					<span className={cl(styles.status__text, styles.failed)}>{t('administration.noAccess')}</span>
					<div className={styles.status__buttonContainer}>
						<Button
							buttonClassName={styles.status__button}
							onClick={() => handleSetRequest(item, grant)}
						>
							{t('administration.request')}
						</Button>
					</div>
				</div>
			),
		}

		return {
			button: button[grant.status],
			switch: (
				<Field name={FORM_ITEM.status}>
					{({ field, form, meta }) => {
						let formItem = form?.values?.array?.[outerKey]?.grants?.[index] || {}
						let {isEdited, ...currentItemForm} = formItem
						let oldData = item.grants?.[index]

						let current = {
							true: t('administration.you_set_access'),
							false: t('administration.you_revoke_access'),
						}

						return (
							<div className={styles.status__buttonContainer}>
								<Switch sx={{ m: 1 }}
												checked={currentItemForm?.status || false}
												onChange={e => handleChangeValues(form, grant, index, e.target.checked)}
								/>
								<span className={cl(styles.subtitle, styles.status__checkboxText)}>{
									isEdited ? (isEqual(currentItemForm, oldData) ? '' : current[currentItemForm?.status]) : (currentItemForm?.status === null && t('administration.set_request_from_you'))
								}</span>
							</div>
						)
					}}
				</Field>
			),
		}
	}, [t, handleSetRequest])

	return (
		<div className={styles.wrapper}>
			<span className={cl(styles.title)}>
				{lang === 'rus' ? item.orgNameRu : item.orgNameKz}
			</span>

			<span className={styles.subtitle}>
				{t('ckp_BIN')}: {item.bin}
			</span>

			<div className={styles.list}>
				<div>
					{item.grants?.map((grant, grantIdx) => (
						<div key={grant.grantCode} className={styles.list__item}>
							<div className={styles.textContainer}>
								<span className={cl(styles.title)}>
									{title[grant.grantCode]}
								</span>
								{subtitle[grant.grantCode] &&
									<span className={cl(styles.subtitle)}>
											{subtitle[grant.grantCode]}
										</span>
								}
							</div>

							{company.bin !== item.bin && content(grant, grantIdx)[type]}
						</div>
					))}
				</div>
			</div>
		</div>
	)
})