import React, { useEffect, useMemo, useState } from 'react'
import { TreeView } from '@features/staffing/tableTreeView/TableTreeView'
import { SendPeople } from '@app/images'
import { Button, Title } from '@/ui'
import { useTranslation } from 'react-i18next'
import Search from '@features/staffing/search/Search'
import { getFromLocalStorage, useDigitalSign, useToggle } from '@/shared'
import TableExtended from '@features/staffing/tableExtended/tableExtended'
import styles from './Staffing.module.scss'
import { StaffingPositionForm } from './StaffingPositionForm'
import { initialStaffingPosition } from '@/entity/staffingProject/reducer'
import { useDispatch, useSelector } from 'react-redux'
import { staffingActions, staffingSelectors, staffingThunks } from '@/entity/staffing'
import { View } from './StaffingPositionForm/view/View'
import { employerProfileSelectors } from '@features/employerProfile/model'
import { contractActionsSelectors } from '@features/contracts/model'
import { calculateStaffUnits, getDeptAndDeptTypeCodeByExternalId, getWorkPlaces } from '@/utils'
import ModalGetNewVersion from './ModalGetNewVersion/ModalGetNewVersion'
import { InfoWarning } from '@/components/infoWarning'
import { getCurrentLanguage } from '@/i18next'

const initialFilterState = {
	srcText: '',
}
const Staffing = () => {
	const { t } = useTranslation()
	const activeCompany = useSelector(employerProfileSelectors.activeCompany)
	const staffing = useSelector(staffingSelectors.staffing)
	const esutdInfo = useSelector(staffingSelectors.esutdInfo)
	const isLoading = useSelector(staffingSelectors.isLoading)
	const [totalStaffUnits, setTotalStaffUnits] = useState(0)
	const [totalFreeStaffUnits, setTotalFreeStaffUnits] = useState(0)
	const { staffType } = useSelector(contractActionsSelectors.options)
	const staffXML = useSelector(staffingSelectors.staffXML)
	const employeeListXML = useSelector(staffingSelectors.employeeListXML)
	const dispatch = useDispatch()
	const [filters, setFilters] = useState(initialFilterState)
	const [isViewExtended, toggleViewExtended] = useToggle(false)
	const [activeStructId, setActiveStructId] = useState(null)
	const [workPlaceRowSelect, setWorkPlaceRowSelect] = useState(null)
	const [openModalSign, setOpenModalSign] = useToggle(false)
	const [deactivateStaff, setDeactivateStaff] = useState(false)
	const { successPayload, onSign, onSignMultiple, failedPayload, onClearPayload } = useDigitalSign()
	const lang = getCurrentLanguage()
	const [actions, setActions] = useState({
		create: false,
		view: false,
		edit: false,
	})
	const activeStaffBranch = getFromLocalStorage('activeStaffBranch')

	const {orgsList, staffingGrants} = useSelector((state) => ({
		orgsList: state.administration.staffingBranchesList,
		staffingGrants: state.administration.staffingGrants,
	}))

	const title = useMemo(() => {
		let res = orgsList.data?.find(item => item.bin == activeStaffBranch)

		let byLang = {
			rus: res?.orgNameRu,
			kz: res?.orgNameKz
		}

		return byLang?.[lang]
	}, [orgsList.data, activeStaffBranch, lang])

	const staffingPosition = {
		...initialStaffingPosition,
	}

	const workPlaces = useMemo(
		() =>
			staffing && staffing?.externalId.length > 0 && activeStructId !== null
				? getWorkPlaces(staffing, activeStructId)
				: [],
		[staffing, activeStructId]
	)
	const structure = useMemo(
		() =>
			staffing && staffing?.externalId.length > 0 && activeStructId !== null
				? getDeptAndDeptTypeCodeByExternalId(staffing, activeStructId)
				: '',
		[staffing, activeStructId]
	)
	const onClose = () => {
		let actionObj = {
			create: false,
			view: false,
		}

		if (actions.view && actions.create) {
			actionObj.create = false
			actionObj.view = true
		}
		if (!actions.view) {
			dispatch(staffingActions.resetWPlaceEmployee())
		}

		setActions((prevActions) => ({ ...prevActions, ...actionObj }))
		setOpenModalSign(false)
	}
	const onToggleAction = (action, value) => {
		setActions((prevState) => {
			return { ...prevState, [action]: value }
		})
	}

	useEffect(() => {
		if (staffing?.actual) {
			const { totalStaffUnits, totalFreeStaffUnits } = calculateStaffUnits(staffing.childs[0])
			setTotalStaffUnits(totalStaffUnits)
			setTotalFreeStaffUnits(totalFreeStaffUnits)
		}
		if (esutdInfo?.preparedStatus === 'PREPARED') {
			setOpenModalSign(true)
			setDeactivateStaff(true)
		}
	}, [staffing])

	const signAndSendActualStaff = () => {
		dispatch(staffingThunks.getActualXml(staffing.externalId))
		onClose()
	}

	const signAndSendEmployee = () => {
		dispatch(staffingThunks.getEmployeeListXml())
	}

	useEffect(() => staffXML && onSign(staffXML), [staffXML])

	useEffect(() => employeeListXML && onSignMultiple(employeeListXML), [employeeListXML])

	const onClear = () => {
		onClearPayload()
		dispatch(staffingActions.setStaffXml(null))
		dispatch(staffingActions.setEmployeeXml(null))
	}

	useEffect(() => {
		failedPayload && onClear()
	}, [failedPayload])

	useEffect(() => {
		if (successPayload) {
			if (!employeeListXML) {
				dispatch(staffingThunks.sendActualSignStaffing(successPayload))
			} else {
				dispatch(staffingThunks.sendEmployeeList(successPayload))
			}
			onClearPayload()
		}
	}, [successPayload])

	return (
		<>
			{openModalSign ? (
				<ModalGetNewVersion onSubmit={signAndSendActualStaff} onClose={onClose}></ModalGetNewVersion>
			) : null}
			{deactivateStaff ? (
				<div className={styles.mb}>
					<InfoWarning />
				</div>
			) : null}
			<div className={styles.buttonSearchPanel}>
				{!!staffing && !isLoading && !deactivateStaff && staffingGrants?.data?.stRegistration ? (
					<Button
						icon={SendPeople}
						iconSize={'big'}
						onClick={() => {
							signAndSendEmployee()
						}}
					>
						{t('send_appointment')}
					</Button>
				) : null}
				{/*TODO Временно комментируется 17.05.2024*/}
				{/*<Search filters={filters} setFilters={setFilters}></Search>*/}
			</div>
			{isViewExtended ? (
				<>
					<TableExtended
						toggleViewExtended={toggleViewExtended}
						staffType={staffType}
						structure={structure}
						workPlaces={workPlaces}
						setActions={setActions}
						setWorkPlaceRowSelect={setWorkPlaceRowSelect}
						deactivateStaff={deactivateStaff}
					/>
					{actions.create ? (
						<StaffingPositionForm
							initialValues={staffingPosition}
							onClose={onClose}
							actions={actions}
							setActions={setActions}
							workPlaceRowSelect={workPlaceRowSelect}
						></StaffingPositionForm>
					) : null}
					{actions.view ? (
						<View
							onClose={onClose}
							onToggleAction={onToggleAction}
							workPlaceRowSelect={workPlaceRowSelect}
							deactivateStaff={deactivateStaff}
						></View>
					) : null}
				</>
			) : (
				<div className={styles.mt}>
					<div className={styles.countStaff}>
						<Title>{title || ''}</Title>
						<p>
							{t('count_staff_units_1')}
							<span>{totalStaffUnits}</span>
							{t('count_staff_units_2')}
							<span>{totalFreeStaffUnits}</span>
						</p>
					</div>
					{staffing?.currVersion ? (
						<div className={styles.information}>
							<div className={styles.tableText}>
								{t('staff.version') +
									staffing?.currVersion +
									', ' +
									t('staff_modal.order_number') +
									': ' +
									staffing?.orderNumber +
									', ' +
									t('staff_modal.order_date') +
									': ' +
									staffing?.orderDate}
							</div>
						</div>
					) : null}
					<TreeView
						staffing={staffing}
						staffType={staffType}
						toggleViewExtended={toggleViewExtended}
						activeStructId={activeStructId}
						setActiveStructId={setActiveStructId}
					/>
				</div>
			)}
		</>
	)
}

export default Staffing
