import * as types from './types'

const data = {
	loading: false,
	success: false,
	failed: false,
	data: null,
}

const initialState = {
	branchesList: data,
	staffingBranchesList: data,
	staffingGrants: data,
	
}

export const reducer = (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case types.GET_BRANCHES:
			return { ...state, branchesList: { ...data, loading: true } }

		case types.GET_BRANCHES_DONE:
			return { ...state, branchesList: { ...data, data: payload, success: true } }

		case types.GET_BRANCHES_FAIL:
			return { ...state, branchesList: { ...data, failed: true } }


		case types.SET_GRANTS:
			return {
				...state,
				branchesList: {
					...state.branchesList,
					loading: true,
					success: false,
					failed: false,
				},
			}

		case types.SET_GRANTS_DONE:
			const buttonStatus = {
				false: false,
				true: null,
			}

			return {
				...state,
				branchesList: {
					...state.branchesList,
					loading: false,
					success: true,
					data: state.branchesList.data?.map(item => {
						if (item.bin == payload.bin) {
							return {
								...item,
								grants: item.grants?.map(grant => {
									if (grant.grantCode === payload.grantTypeCode) {
										return {
											...grant,
											status: buttonStatus[payload.type === 'request'],
										}
									} else {
										return grant
									}
								}),
							}
						} else {
							return item
						}
					}),
				},
			}

		case types.SET_GRANTS_FAIL:
			return {
				...state,
				branchesList: {
					...state.branchesList,
					loading: false,
					success: false,
					failed: true,
				},
			}


		case types.SEND_SIGNED_XML:
			return {
				...state,
				branchesList: {
					...state.branchesList,
					loading: true,
					success: false,
					failed: false,
				},
			}

		case types.SEND_SIGNED_XML_DONE:
			return {
				...state,
				branchesList: {
					...state.branchesList,
					loading: false,
					success: true,
					data: state.branchesList?.data?.map(item => {
						const changedItem = payload?.find(changedObj => changedObj.bin == item.bin)

						if (item.bin == changedItem?.bin) {
							return {
								...item,
								grants: item.grants?.map(grant => {
									const changedGrant = changedItem?.grants?.find(changedObj => changedObj.grantCode === grant.grantCode)

									if (grant.grantCode === changedGrant?.grantCode) {
										return changedGrant
									} else {
										return grant
									}
								}),
							}
						} else {
							return item
						}
					}),
				},
			}

		case types.SEND_SIGNED_XML_FAIL:
			return {
				...state,
				branchesList: {
					...state.branchesList,
					loading: false,
					success: false,
					failed: true,
				},
			}


		case types.GET_STAFFING_ADMINISTRATION_ORGS:
			return { ...state, staffingBranchesList: { ...data, loading: true } }

		case types.GET_STAFFING_ADMINISTRATION_ORGS_DONE:
			return { ...state, staffingBranchesList: { ...data, data: payload, success: true } }

		case types.GET_STAFFING_ADMINISTRATION_ORGS_FAIL:
			return { ...state, staffingBranchesList: { ...data, failed: true } }


		case types.SET_STAFFING_ADMINISTRATION_ORG:
			return { ...state, staffingBranchesList: { ...state.staffingBranchesList, loading: true } }

		case types.SET_STAFFING_ADMINISTRATION_ORG_DONE:
			return { ...state, staffingBranchesList: { ...state.staffingBranchesList, loading: false, success: true, failed: false } }

		case types.SET_STAFFING_ADMINISTRATION_ORG_FAIL:
			return { ...state, staffingBranchesList: { ...data, failed: true } }


		case types.GET_STAFFING_GRANTS:
			return { ...state, staffingGrants: { ...data, loading: true } }

		case types.GET_STAFFING_GRANTS_DONE:
			return { ...state, staffingGrants: { ...data, data: payload, success: true } }

		case types.GET_STAFFING_GRANTS_FAIL:
			return { ...state, staffingGrants: { ...data, failed: true } }

		default:
			return state
	}
}
