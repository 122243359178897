export const GET_SICK_DATA = 'sickLeave/GET_SICK_DATA'
export const GET_SICK_DATA_DONE = 'sickLeave/GET_SICK_DATA_DONE'
export const GET_SICK_DATA_FAIL = 'sickLeave/GET_SICK_DATA_FAIL'

export const GET_ACTUAL_CONTRACT = 'sickLeave/GET_ACTUAL_CONTRACT'
export const GET_ACTUAL_CONTRACT_DONE = 'sickLeave/GET_ACTUAL_CONTRACT_DONE'
export const GET_ACTUAL_CONTRACT_FAIL = 'sickLeave/GET_ACTUAL_CONTRACT_FAIL'

export const GET_EMPLOYEE_PHONE = 'sickLeave/GET_EMPLOYEE_PHONE'
export const GET_EMPLOYEE_PHONE_DONE = 'sickLeave/GET_EMPLOYEE_PHONE_DONE'
export const GET_EMPLOYEE_PHONE_FAIL = 'sickLeave/GET_EMPLOYEE_PHONE_FAIL'

export const SEND_SMS = 'sickLeave/SEND_SMS'
export const SEND_SMS_DONE = 'sickLeave/SEND_SMS_DONE'
export const SEND_SMS_FAIL = 'sickLeave/SEND_SMS_FAIL'

export const SET_SICK_LEAVE = 'sickLeave/SET_SICK_LEAVE'
export const SET_SICK_LEAVE_DONE = 'sickLeave/SET_SICK_LEAVE_DONE'
export const SET_SICK_LEAVE_FAIL = 'sickLeave/SET_SICK_LEAVE_FAIL'