import { request } from '@/shared/api/request'

export const addWorkPlace = ({data, params, payload: {multiCreate = true}}) => {
	let currentParams = {
		multiCreate: multiCreate,
		...params
	}

	return request({
		method: 'POST',
		url: `staff/workPlace/add`,
		data: data,
		params: currentParams,
	})
}
