import { request } from '@/shared/api/request'

export const removeStructure = ({data, params}) => {
	let currentParams = {
		...params,
		delWPlace: true
	}
	return request({
		method: 'POST',
		url: 'staff/structure/del',
		data,
		params: currentParams
	})
}
