import React from 'react'
import styles from './WorkplaceInformation.module.scss'
import { DataGridTable } from '@/ui'
import { useTranslation } from 'react-i18next'
import { formatNumber } from '@/utils'
import { useSelector } from 'react-redux'
import { contractActionsSelectors } from '@features/contracts/model'
import { getCurrentLanguage } from '@/i18next'

export const WorkplaceInformation = ({ workplace }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const { positionsByKnz, laborMobilityCenterCategory } = useSelector(contractActionsSelectors.options)
	const langPositionName = lang === 'rus' ? 'rus' : 'kz'
	let positionName = positionsByKnz?.find((item) => item.code === workplace.positionCode)
	let positionCategory = laborMobilityCenterCategory?.find((item) => item.code === workplace.staffCategory)

	const workplaceInformation = () => {
		return [
			{ title: t('staffing_table.workplace_code_label'), data: workplace.code },
			{ title: t('staff_modal.position_kz'), data: workplace.establishedPost.nameKz },
			{ title: t('staff_modal.position_ru'), data: workplace.establishedPost.nameRu },
			{ title: t('contract.info.nkz_position'), data: positionName?.[langPositionName] || '' },
			...(workplace.staffCategory
				? [{ title: t('staff_modal.position_category'), data: positionCategory?.[langPositionName] }]
				: []),
			{ title: t('staff_modal.freelancer'), data: workplace.freelance ? t('yes') : t('no') },
			{ title: t('staff.count_bets'), data: workplace.staffUnitsCount + '/' + workplace.staffFreeCount },
			{ title: t('staffing_table.salary'), data: formatNumber(workplace.grossSalary || '') },
		]
	}
	return (
		<DataGridTable
			hideSubTitle
			titleClass={styles.tableTitleCell}
			title={t('workplace.title')}
			information={workplaceInformation()}
		/>
	)
}
