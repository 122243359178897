import React, { useRef, useState, isValidElement } from 'react'
import { Arrow } from '@app/icons'
import styles from './DropdownMenu.module.scss'
import { useClickOutside } from '@/shared'

const DropDownMenu = ({ title, items, onItemClick, className, onButtonClick }) => {
	const [show, setShow] = useState(false)
	const ref = useRef(null)

	const _onButtonClick = (e) => {
		onButtonClick && onButtonClick(e)
		setShow((show) => !show)
	}

	const _onItemClick = (e) => {
		onItemClick(e)
		setShow(false)
	}

	useClickOutside(ref, show, () => setShow(false))

	return (
		<div ref={ref}>
			<button type={'button'} onClick={_onButtonClick} className={`${styles.dropdownButton} ${className}`}>
				{title} <Arrow className={styles.icon} />
			</button>
			<div className={styles.menu}>
				<ul className={`${styles.ul} ${show ? styles.shown : styles.hidden} `}>
					{items?.map((item, index) => (
						<li onClick={(e) => _onItemClick(item?.action || e)} key={item?.action || index}>
							{item.icon && (isValidElement(item.icon) ? item.icon : <img src={item.icon} alt="menu-icon" />)}
							{item?.title}
						</li>
					))}
				</ul>
			</div>
		</div>
	)
}
export default DropDownMenu
